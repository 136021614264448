import { makeAutoObservable } from "mobx";
import isEmail from "validator/lib/isEmail";
import { PaymentTemplate } from "../../../../types/payment";
import { ErrorState } from "../../../../helpers/error";
import { PaymentApiClient } from "../../../../api/payment.api.client";

export type FormState = {
  amount: string;
  isAmountReadOnly: boolean;
  formattedAmount: string;
  description: string;
  isDescriptionReadOnly: boolean;
  name: string;
  email: string;
};

const defaultMaxAmount = 100000;
const decimalPoint = ".";

type PaymentFormState = "loading" | ErrorState | FormState;
type AmountValidationType = "max" | "min" | null;

const formatNumberWithCommas = (value: string) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const parseValue = (value: string): number =>
  parseFloat(`0${value.replace(/[^0-9,.]/gi, "").replace(",", decimalPoint)}`);

export const formatValue = (value: number, digits = 2): string => (value === 0 ? "" : value.toFixed(digits));

export const normalizeValue = (value: string): string => formatNumberWithCommas(formatValue(parseValue(value)));

function createFormState(template: PaymentTemplate): FormState {
  return {
    amount: template.amount || "0",
    isAmountReadOnly: !!template.amount,
    formattedAmount: normalizeValue(template.amount),
    description: template.description || "",
    isDescriptionReadOnly: !!template.description,
    name: "",
    email: "",
  };
}

export function validateValue(template: PaymentTemplate, formState: PaymentFormState, minAmount: number): AmountValidationType {
  if (formState === "loading" || formState instanceof ErrorState) {
    return null;
  }

  let maxAmount;
  const { maxAmount: maxAmountFromServer } = template;
  if (!maxAmountFromServer) {
    maxAmount = defaultMaxAmount;
  } else {
    maxAmount = Number(maxAmountFromServer);
  }

  const amount = Number(formState.amount);

  if (amount < minAmount) {
    return "min";
  }

  if (amount > maxAmount) {
    return "max";
  }

  return null;
}

export function isEmailValid(email: string): boolean {
  return !email || isEmail(email);
}

export function isNameValid(name: string): boolean {
  return !name || name.length >= 3;
}

export class PaymentLinkFormStore {
  private _state: PaymentFormState = "loading";

  private _loader = false;

  private _redirectUrl: string | null = null;

  private _api: PaymentApiClient;

  constructor(client: PaymentApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): PaymentFormState {
    return this._state;
  }

  set state(state: PaymentFormState) {
    this._state = state;
  }

  get inProgress(): boolean {
    return this._loader;
  }

  private set inProgress(loader: boolean) {
    this._loader = loader;
  }

  get redirectUrl(): string | null {
    return this._redirectUrl;
  }

  private set redirectUrl(url: string | null) {
    this._redirectUrl = url;
  }

  public async load(template: PaymentTemplate): Promise<void> {
    if (!template) {
      this.state = new ErrorState("Oops.. Missed payment template id in URL");
      return;
    }
    this.state = createFormState(template);
  }

  onAmountChange(form: FormState, amount: string, format?: boolean): void {
    this.state = {
      ...form,
      amount,
      formattedAmount: format ? normalizeValue(amount) : amount,
    };
  }

  onDescriptionChange(form: FormState, description: string): void {
    this.state = {
      ...form,
      description,
    };
  }

  onNameChange(form: FormState, name: string): void {
    this.state = {
      ...form,
      name,
    };
  }

  onEmailChange(form: FormState, email: string): void {
    this.state = {
      ...form,
      email,
    };
  }

  async makeBankTransaction(form: FormState, template: PaymentTemplate): Promise<void> {
    await this.makeTransaction("providers-selection", form, template);
  }

  async makeCardTransaction(
    form: FormState,
    template: PaymentTemplate,
    onCardPaymentNotAllowed: () => void
  ): Promise<void> {
    if (!template.allowCardPayments) {
      onCardPaymentNotAllowed();
      await this._api.registerNotAllowedCardPaymentAttempt(template.templateId);
      return;
    }
    await this.makeTransaction("card-details", form, template);
  }

  private async makeTransaction(paymentPage: string, form: FormState, template: PaymentTemplate): Promise<void> {
    this.inProgress = true;
    const { templateId, productId } = template;
    try {
      const { payment_request_id: paymentRequestId } = await this._api.makePayment(
        templateId,
        form.amount,
        form.description,
        form.name,
        form.email,
        productId
      );

      this.redirectUrl = `${window.location.origin}/${paymentPage}?payment_request_id=${paymentRequestId}`;
    } catch (e) {
      this.state = new ErrorState("Error in creating a payment session");
      throw e;
    }
  }
}
