import React, { ChangeEvent, VFC } from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, useAnnaAppContext } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import styles from "./paySection.module.scss";
import { PaymentPageForm } from "../../../../types/paymentPage";
import { currencySymbol } from "../../../../helpers/constants";
import { ObPaymentPageViewFormType, ObPaymentPageViewStore } from "../obPaymentPageViewStore";
import { Form } from "../../../../components/form/form";
import { Loader } from "../../../../components/loader/loader";
import { gaEventName, gaSendUserEvent } from "../../../../api/analytics.api.client";
import { useModal } from "../../../../helpers/reactHooks";
import { Button } from "../../../../components/button/button";
import { formatValue, normalizeValue, parseValue } from "../../../paymentLink/components/form/formStore";
import { defaultMaxAmount, validateAmount } from "../../validation";
import { ErrorView } from "../../../../components/errorView/errorView";
import { PaymentInfo } from "../headerViewSection/headerViewSection";
import { CardPaymentNotAllowedModal } from "../../../../components/cardPaymentNotAllowed/cardPaymentNotAllowedModal";
import { Input } from "../../../../components/input/input";

export type PaySectionType = {
  state: PaymentPageForm;
  store: ObPaymentPageViewStore;
  form: Form<ObPaymentPageViewFormType>;
};

export const PaySection: VFC<PaySectionType> = observer(({ store, form, state }) => {
  const isMobile = useAnnaAppContext().displayType === "mobile";

  const { amount, currency, description, agreement } = form.values;
  const { makeTransactionState } = store;
  const modal = useModal();
  const {
    beneficiaryName,
    beneficiarySortCode,
    beneficiaryAccountNumber,
    paymentLinkDescription,
    maxAmount,
    allowBankTransfers,
    allowCardPayments,
    paymentTemplatePublicId,
    showPayByCard,
  } = state;

  const handleMakeCardPaymentButtonClick = async (formValue: Form<ObPaymentPageViewFormType>): Promise<void> => {
    if (!store.inProgress && form.isValid()) {
      gaSendUserEvent(gaEventName.clickConnectByStripe);
      await store.makeCardTransaction(allowCardPayments, formValue, paymentTemplatePublicId, modal.open);
    }
  };

  const handleMakeBankPaymentButtonClick = async (formValue: Form<ObPaymentPageViewFormType>): Promise<void> => {
    if (!store.inProgress && form.isValid()) {
      gaSendUserEvent(gaEventName.clickConnectByTrueLayer);
      await store.makeBankTransaction(formValue, paymentTemplatePublicId);
    }
  };

  const renderPaymentLinkDescription = () => (
    <div className={styles.fieldRow}>
      <div className={styles.descriptionBox}>For {paymentLinkDescription}</div>
    </div>
  );

  const amountSizeError =
    validateAmount(maxAmount, amount) === "max" &&
    `* Sorry, our maximum for one payment is £${normalizeValue(maxAmount || defaultMaxAmount.toString())}`;
  return (
    <>
      <div className={styles.wrapper}>
        <span className={styles.subtitle}>Pay this business</span>
        {isMobile && (
          <PaymentInfo
            beneficiaryName={beneficiaryName}
            beneficiarySortCode={beneficiarySortCode}
            beneficiaryAccountNumber={beneficiaryAccountNumber}
          />
        )}
        <div className={styles.fieldRow}>
          <div className={styles.column}>
            <Input
              label="Amount"
              className={classNames(styles.field, isMobile && styles.mobile)}
              type="number"
              min={0}
              prefix={currencySymbol[currency]}
              onBlur={async (event) => amount && form.onChange("amount", formatValue(parseValue(event.target.value)))}
              inputMode="decimal"
              hasError={!!form.getError("amount") || !!amountSizeError}
              placeholder="0.00"
              onChange={async (e: ChangeEvent<HTMLInputElement>) => form.onChange("amount", e.target.value)}
              value={amount}
              error={form.getError("amount") || amountSizeError}
            />
          </div>
        </div>
        {!paymentLinkDescription && (
          <div className={styles.fieldRow}>
            <Input
              label="Description"
              className={styles.field}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => form.onChange("description", e.target.value)}
              value={description}
              error={form.getError("description")}
            />
          </div>
        )}
        {isMobile && paymentLinkDescription && renderPaymentLinkDescription()}
        <div className={classNames(styles.fieldRow, isMobile && styles.mobile)}>
          <div className={classNames(styles.fieldGroup, isMobile && styles.mobile)}>
            <Input
              label="Your name"
              className={styles.field}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => form.onChange("name", e.target.value)}
              value={form.getValue("name")}
              error={form.getError("name")}
            />
          </div>
          <div className={classNames(styles.fieldGroup, isMobile && styles.mobile)}>
            <Input
              label="Email"
              className={classNames(styles.field, styles.name, isMobile && styles.mobile)}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => form.onChange("email", e.target.value)}
              value={form.getValue("email")}
              error={form.getError("email")}
            />
          </div>
        </div>
        {paymentLinkDescription && !isMobile && renderPaymentLinkDescription()}
        <div className={styles.fieldRow}>
          <Checkbox
            hasError={!!form.getError("agreement")}
            weight="semibold"
            checked={agreement}
            onChange={() => form.onChange("agreement", !agreement)}
            label={
              <span className={styles.agreementText}>
                I agree to&#160;
                <a
                  className={classNames(styles.link, !!form.getError("agreement") && styles.error)}
                  href="https://anna.money/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  ANNA’s privacy policy
                </a>
              </span>
            }
          />
        </div>
        {makeTransactionState === "error" && <ErrorView>Error in creating a payment session</ErrorView>}
        <div
          className={classNames(styles.fieldRow, styles.buttonsBlock, !showPayByCard && styles.justifiedButtonsBlock)}
        >
          {allowBankTransfers && (
            <Button
              className={styles.button}
              disabled={form.hasErrors}
              onClick={() => handleMakeBankPaymentButtonClick(form)}
            >
              {store.inProgress ? <Loader /> : "Pay by bank transfer"}
            </Button>
          )}
          {showPayByCard && (
            <Button
              className={styles.button}
              disabled={form.hasErrors}
              type="secondary"
              onClick={() => handleMakeCardPaymentButtonClick(form)}
            >
              {store.inProgress ? <Loader /> : "Pay by card"}
            </Button>
          )}
          <span className={styles.button}>
            <Button
              type="link"
              onClick={() => gaSendUserEvent(gaEventName.clickFaq)}
              href="https://anna.money/blog/updates/your-anna-payment-link-a-new-handy-way-to-accept-payments#p2"
            >
              More about payments by ANNA
            </Button>
          </span>
        </div>
      </div>
      <CardPaymentNotAllowedModal visible={modal.visible} onClose={modal.close} name={beneficiaryName} />
    </>
  );
});
