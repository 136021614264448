import { Loader } from "components/loader/loader";
import React, { FC, useEffect, useRef, useState, ReactNode } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useMount } from "../../helpers/reactHooks";
import styles from "./pdfViewer.module.scss";

type RenderState = "loading" | "ready";
type PdfViewerProps = {
  fileUrl: string;
  documentClassName?: string;
  pageClassName?: string;
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PdfViewer: FC<PdfViewerProps> = ({ fileUrl, documentClassName, pageClassName }) => {
  const [pageWidth, updateWidth] = useState(0);
  const [renderState, setRenderState] = useState<RenderState>("loading");
  const [pagesCount, setPagesCount] = useState(0);
  const documentRef = useRef<HTMLDivElement>(null);

  useMount(() => {
    setRenderState("ready");
  });

  useEffect(() => {
    if (!documentRef.current) {
      return;
    }
    const { width } = documentRef.current.getBoundingClientRect();
    if (pageWidth !== width) {
      updateWidth(width);
    }
  }, [documentRef.current]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPagesCount(numPages);
  };

  const renderDocumentContent = (): ReactNode =>
    Array.from(new Array(pagesCount), (_, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        className={pageClassName || styles.page}
        width={pageWidth}
      />
    ));

  return (
    <div className={documentClassName || styles.document} ref={documentRef}>
      {renderState === "ready" && (
        <Document
          key={fileUrl}
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className={documentClassName || styles.document}
          loading={<Loader />}
        >
          {renderDocumentContent()}
        </Document>
      )}
    </div>
  );
};
