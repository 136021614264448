import React, { FC } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Routes } from "react-router";
import { Confirmation } from "sections/confirmation/confirmation";
import { Status } from "components/status/status";
import { NoMatch } from "../components/noMatch/noMatch";
import { Config, Services } from "../services";
import { useFactory } from "../helpers/reactHooks";
import { AppServices } from "./appServices";
import { paymentRoutes } from "../sections/payment/paymentRoutes";
import { paymentLinkRoutes } from "../sections/paymentLink";
import { invoiceLinkRoutes } from "../sections/invoiceLink/invoiceLinkRoutes";
import { ObPaymentPageForm } from "../sections/obPaymentPage/obPaymentPageForm";
import { ObPaymentPageView } from "../sections/obPaymentPage/obPaymentPageView/obPaymentPageView";
import { CloseView } from "../sections/obPaymentPage/components/closeView/closeView";

type AppProps = { config: Config };

export const App: FC<AppProps> = ({ config }) => {
  const services = useFactory(() => new Services(config));

  return (
    <AppServices services={services}>
      <Router>
        <Routes>
          {paymentLinkRoutes}
          {paymentRoutes}
          {invoiceLinkRoutes}
          <Route path="/payment-pages/close" element={<CloseView />} />
          <Route path="/payment-pages/new" element={<ObPaymentPageForm />} />
          <Route path="/payment-pages/:id/edit" element={<ObPaymentPageForm />} />
          <Route path="/pp/:id" element={<ObPaymentPageView />} />
          <Route path="status/:paymentId/:state" element={<Confirmation />} />
          <Route
            path="payment/success"
            element={
              <Status type="success">
                <div>Payment successful</div>
                <div>Thank you for your business</div>
              </Status>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </AppServices>
  );
};
