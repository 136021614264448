import React, { VFC, FormEvent, useState } from "react";
import { AddressElement, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styles from "./CardPage.module.scss";
import { Status } from "../../../components/status/status";
import { Loader } from "../../../components/loader/loader";

export const CheckoutForm: VFC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.");
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/success`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  if (!stripe || !elements) {
    return <Status type="progress" />;
  }

  return (
    <form className={styles.paymentForm} id="payment-form" onSubmit={handleSubmit}>
      <AddressElement options={{ mode: "billing" }} />
      <PaymentElement className={styles.paymentElement} id="payment-element" />
      <button className={styles.payButton} type="submit" disabled={isLoading || !stripe || !elements} id="submit">
        <span>{isLoading ? <Loader /> : "Pay now"}</span>
      </button>
      {message && (
        <div className={styles.paymentMessage} id="payment-message">
          {message}
        </div>
      )}
    </form>
  );
};
