import { makeAutoObservable } from "mobx";
import { PaymentApiClient } from "../../../api/payment.api.client";
import { Form } from "../../../components/form/form";
import { formatPaymentPageToForm } from "../formatters";
import { PaymentPageForm } from "../../../types/paymentPage";
import { validationViewPageConfig } from "../validation";

export type ObPaymentPageViewFormType = {
  amount: string;
  description: string;
  name: string;
  email: string;
  currency: string;
  agreement: boolean;
};

export type ObPaymentPageFormType = {
  form: Form<ObPaymentPageViewFormType>;
  pageData: PaymentPageForm;
};

type ObPaymentPageFormState = "loading" | "error" | ObPaymentPageFormType;
type MakeTransactionState = "loading" | "error" | "success";

export class ObPaymentPageViewStore {
  private _state: ObPaymentPageFormState = "loading";

  private _makeTransactionState: MakeTransactionState = "loading";

  private _loader = false;

  private _redirectUrl: string | null = null;

  private _api: PaymentApiClient;

  constructor(client: PaymentApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): ObPaymentPageFormState {
    return this._state;
  }

  private set state(state: ObPaymentPageFormState) {
    this._state = state;
  }

  set makeTransactionState(makeTransactionState: MakeTransactionState) {
    this._makeTransactionState = makeTransactionState;
  }

  get makeTransactionState(): MakeTransactionState {
    return this._makeTransactionState;
  }

  get inProgress(): boolean {
    return this._loader;
  }

  private set inProgress(loader: boolean) {
    this._loader = loader;
  }

  get redirectUrl(): string | null {
    return this._redirectUrl;
  }

  private set redirectUrl(url: string | null) {
    this._redirectUrl = url;
  }

  async makeBankTransaction(form: Form<ObPaymentPageViewFormType>, paymentTemplatePublicId: string): Promise<void> {
    await this.makeTransaction("providers-selection", form, paymentTemplatePublicId);
  }

  async makeCardTransaction(
    allowCardPayments: boolean,
    form: Form<ObPaymentPageViewFormType>,
    paymentTemplatePublicId: string,
    onCardPaymentNotAllowed: () => void
  ): Promise<void> {
    if (!allowCardPayments) {
      onCardPaymentNotAllowed();
      await this._api.registerNotAllowedCardPaymentAttempt(paymentTemplatePublicId);
      return;
    }
    await this.makeTransaction("card-details", form, paymentTemplatePublicId);
  }

  private async makeTransaction(
    paymentPage: string,
    form: Form<ObPaymentPageViewFormType>,
    paymentTemplatePublicId: string
  ): Promise<void> {
    this.inProgress = true;
    const { amount, description, name, email } = form.values;

    try {
      const { payment_request_id: paymentRequestId } = await this._api.makePayment(
        paymentTemplatePublicId,
        amount,
        description,
        name,
        email,
        ""
      );
      this.makeTransactionState = "success";
      this.redirectUrl = `${window.location.origin}/${paymentPage}?payment_request_id=${paymentRequestId}`;
    } catch (e) {
      this.makeTransactionState = "error";
      throw e;
    }
  }

  async load(id: string): Promise<void> {
    try {
      this.state = "loading";
      const data = await this._api.getPaymentPage(id);
      const pageData = formatPaymentPageToForm(false, data);

      const form = new Form(
        {
          amount: pageData.amount || "",
          description: pageData.paymentLinkDescription || "",
          email: "",
          name: "",
          currency: pageData.currency,
          agreement: false,
        },
        validationViewPageConfig
      );

      this.state = {
        form,
        pageData,
      };
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }
}
