import React, { FC, ReactNode } from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";

type ButtonProps = {
  href?: string;
  target?: string;
  type?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  suffix?: ReactNode;
};

export const Button: FC<ButtonProps> = ({ href, children, onClick, className, type, target, suffix, disabled = false }) => {
  const getButtonNode = () => (
    <button
      type="button"
      disabled={disabled}
      className={classnames(
        type === "link" ? styles.link : styles.button,
        type === "secondary" ? styles.secondary : styles.button,
        disabled && styles.disabled,
        className
      )}
      onClick={disabled || !onClick ? () => undefined : onClick}
    >
      {children}
      {suffix && <span className={styles.suffix}>{suffix}</span>}
    </button>
  );

  if (href) {
    return (
      <a aria-disabled={disabled} className={styles.href} href={href} target={target}>
        {getButtonNode()}
      </a>
    );
  }
  return getButtonNode();
};
