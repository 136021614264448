import { makeAutoObservable } from "mobx";
import { PaymentTemplateRaw, PaymentTemplate } from "../../types/payment";
import { ErrorState } from "../../helpers/error";
import { PaymentApiClient } from "../../api/payment.api.client";

type PaymentLinkState = "loading" | ErrorState | PaymentTemplate;

function createPaymentTemplateState(paymentTemplate: PaymentTemplateRaw, productId?: string): PaymentTemplate {
  const templateId = paymentTemplate.id;

  return {
    maxAmount: paymentTemplate.max_amount,
    thankYouMessage: paymentTemplate.thank_you_message || "",
    allowBankPayments: paymentTemplate.allow_bank_transfers,
    showPayByCard: paymentTemplate.show_pay_by_card,
    allowCardPayments: paymentTemplate.allow_card_payments,
    logoUrl: paymentTemplate.logo_url || "",
    beneficiarySortCode: paymentTemplate.beneficiary_sort_code,
    beneficiaryAccountNumber: paymentTemplate.beneficiary_account_number,
    beneficiaryName: paymentTemplate.beneficiary_name,
    companyTitle: paymentTemplate.company_title,
    companyDescription: paymentTemplate.company_description || "",
    amount: paymentTemplate.amount || "",
    description: paymentTemplate.description || "",
    templateId,
    productId,
  };
}

export class PaymentLinkStore {
  private _state: PaymentLinkState = "loading";

  private _api: PaymentApiClient;

  constructor(client: PaymentApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): PaymentLinkState {
    return this._state;
  }

  private set state(state: PaymentLinkState) {
    this._state = state;
  }

  async load(templateId?: string, productId?: string): Promise<void> {
    if (!templateId) {
      this.state = new ErrorState("Oops.. Missed payment template id in URL");
      return;
    }
    this.state = "loading";
    try {
      const data = await this._api.getTransactionTemplate(templateId, productId);
      this.state = createPaymentTemplateState(data, productId);
    } catch (e) {
      this.state = new ErrorState("Failed to request payment template");
      throw e;
    }
  }
}
