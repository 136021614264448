import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  "Content-Type": "application/json; charset=utf-8",
};

export type AnnaResponseRaw<T> = {
  data: T;
};

export class Api {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    if (this.instance == null) {
      throw Error("Api error: public url not exist");
    }
    return this.instance;
  }

  constructor(baseUrl: string) {
    this.init(baseUrl);
  }

  init(baseURL: string): AxiosInstance {
    const http = axios.create({
      baseURL,
      headers,
    });

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return Api.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.request(config);
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.get<T>(url, config);
  }

  post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.post<T>(url, data, config);
  }

  put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.put<T>(url, data, config);
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.delete<T>(url, config);
  }

  static handleError(error: { status: StatusCode }): Promise<{ status: StatusCode }> {
    return Promise.reject(error);
  }

  async getFile(url: string): Promise<Blob> {
    const response = await this.request<Blob>({
      url,
      method: "GET",
      responseType: "blob",
    });

    return response.data;
  }
}
