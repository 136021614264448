import { makeAutoObservable } from "mobx";
import isEmail from "validator/lib/isEmail";
import { ErrorState } from "../../helpers/error";
import { InvoiceTemplateRaw } from "../../types/invoice";
import { InvoiceApiClient } from "../../api/invoice.api.client";

export type InvoiceTemplate = {
  email: string;
  description: string;
  beneficiaryName: string;
  beneficiaryReference: string;
  amount: string;
  name: string;
  postCode: string;
  address: string;
  invoiceId?: string;
  paymentId?: string;
  logoUrl?: string;
};

type ConfirmationPageState =
  | "loading"
  | ErrorState
  | { status: "process"; invoice: InvoiceTemplate }
  | { status: "success"; invoice: InvoiceTemplate };

export type InvoiceDataState = {
  emailValid?: boolean;
  emailReadonly?: boolean;
  descriptionValid?: boolean;
  descriptionReadonly?: boolean;
  nameValid?: boolean;
  nameReadonly?: boolean;
};

function toInvoiceTemplate(json: InvoiceTemplateRaw): InvoiceTemplate {
  return {
    description: json.description || "",
    beneficiaryName: json.beneficiary_name,
    beneficiaryReference: json.beneficiary_reference,
    amount: json.amount || "",
    email: json.email || "",
    name: json.name || "",
    postCode: "",
    address: "",
    paymentId: json.payment_id,
    invoiceId: json.invoice_id,
    logoUrl: json.logo_url || "",
  };
}

export class ConfirmationStore {
  private _state: ConfirmationPageState = "loading";

  private _dataState: InvoiceDataState = {};

  private readonly _api: InvoiceApiClient;

  constructor(client: InvoiceApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): ConfirmationPageState {
    return this._state;
  }

  private set state(state: ConfirmationPageState) {
    this._state = state;
  }

  get dataState(): InvoiceDataState {
    return this._dataState;
  }

  private set dataState(dataState: InvoiceDataState) {
    this._dataState = dataState;
  }

  async load(paymentId?: string): Promise<void> {
    if (!paymentId) {
      this.state = new ErrorState("Payment identifier is absent");
      return;
    }

    try {
      const invoice = toInvoiceTemplate(await this._api.getInvoiceTemplate(paymentId));

      this.dataState = {
        descriptionValid: true,
        descriptionReadonly: !!invoice.description,
        emailValid: true,
        emailReadonly: !!invoice.email,
        nameReadonly: !!invoice.name,
        nameValid: true,
      };

      this.state = {
        status: "process",
        invoice,
      };
    } catch (e) {
      this.state = new ErrorState("Failed to request invoice details");
      throw e;
    }
  }

  onEmailChange = (value = "", data: InvoiceTemplate): void => {
    const isEmailValueValid = isEmail(value);

    this.dataState = {
      ...this.dataState,
      emailValid: isEmailValueValid,
    };

    this.state = {
      status: "process",
      invoice: {
        ...data,
        email: value,
      },
    };
  };

  onDescriptionChange = (value = "", data: InvoiceTemplate): void => {
    this.dataState = {
      ...this.dataState,
      descriptionValid: !!value,
    };

    this.state = {
      status: "process",
      invoice: {
        ...data,
        description: value,
      },
    };
  };

  onAddressChange = (value = "", data: InvoiceTemplate): void => {
    this.state = {
      status: "process",
      invoice: {
        ...data,
        address: value,
      },
    };
  };

  onPostCodeChange = (value = "", data: InvoiceTemplate): void => {
    this.state = {
      status: "process",
      invoice: {
        ...data,
        postCode: value,
      },
    };
  };

  onNameChange = (value = "", data: InvoiceTemplate): void => {
    this.dataState = {
      ...this.dataState,
      nameValid: !!value,
    };

    this.state = {
      status: "process",
      invoice: {
        ...data,
        name: value,
      },
    };
  };

  canSend(invoice: InvoiceTemplate): {
    invoice: InvoiceTemplate;
    dataState: InvoiceDataState;
  } {
    this.dataState = {
      ...this.dataState,
      nameValid: !!invoice.name,
      emailValid: isEmail(invoice.email),
      descriptionValid: !!invoice.description,
    };

    this.state = {
      status: "process",
      invoice,
    };

    return {
      invoice,
      dataState: this.dataState,
    };
  }

  async submit(invoice: InvoiceTemplate): Promise<void> {
    if (!invoice.paymentId) {
      this.state = new ErrorState("Payment identifier is absent");
      return;
    }
    const data = await this._api.sendInvoice(
      invoice.paymentId,
      invoice.postCode,
      invoice.email,
      invoice.name,
      invoice.address,
      invoice.description
    );
    this.state = {
      status: "success",
      invoice: toInvoiceTemplate(data),
    };
  }
}
