import { useEffect, useRef, useState } from "react";

export function useMount(callback: () => void | Promise<void>): void {
  useEffect(() => {
    callback();
  }, []);
}

export function useFactory<T>(factory: () => T): T {
  const ref = useRef<{ value: T }>();
  ref.current = ref.current ?? { value: factory() };
  return ref.current.value;
}

interface ModalHook {
  visible: boolean;
  open: () => void;
  close: () => void;
}

export function useModal(): ModalHook {
  const [visible, setVisible] = useState(false);
  return {
    visible,
    open: () => setVisible(true),
    close: () => setVisible(false),
  };
}
