import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styles from "./CardPage.module.scss";
import { CheckoutForm } from "./checkoutForm";
import { useMount } from "../../../helpers/reactHooks";
import { Status } from "../../../components/status/status";
import { ErrorState } from "../../../helpers/error";
import { Blocked } from "../../../components/blocked/blocked";
import { useServiceFactory } from "../../../app/appServices";
import { PaymentInfo } from "../../../types/payment";
import { Loader } from "../../../components/loader/loader";

type CardPageProps = {
  details: PaymentInfo;
};

export const CardPage: FC<CardPageProps> = observer(({ details }) => {
  const store = useServiceFactory((x) => x.createCardPageStore());
  const { clientSecret } = details.stripePaymentIntent;
  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  useMount(() => store.load());

  if (!clientSecret || store.state instanceof ErrorState) {
    return (
      <>
        <Status type="error" backUrl={details.backUrl} title="Page load error..." text="We are already working on it" />
        <Blocked
          name={details.toAccountName}
          accountNumber={details.openbankingPaymentRequest.toAccountNumber}
          sortCode={details.openbankingPaymentRequest.toAccountSortCode}
        />
      </>
    );
  }

  if (store.state === "loading") {
    return <Loader className={styles.loader} />;
  }

  return (
    <div className={styles.panelCard}>
      <div className={styles.content}>
        <div className={styles.title}>Enter your card details</div>
        <div className={styles.cardForm}>
          <Elements
            options={options}
            stripe={loadStripe(store.state.apiKey, {
              locale: "en-GB",
              stripeAccount: details.stripePaymentIntent.accountId,
            })}
          >
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </div>
  );
});
