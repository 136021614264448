import React, { VFC } from "react";
import { observer } from "mobx-react-lite";
import { Button as TextButton, Loader } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import { Button } from "../../../../components/button/button";
import styles from "./submitSection.module.scss";
import { ErrorView } from "../../../../components/errorView/errorView";

export const SubmitSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const handleRedirect = () => window.location.replace("/payment-pages/close");

  if (store.saveStatus === "saving") {
    return <Loader />;
  }

  const scrollToError = (): void => {
    const fieldId = store.getFieldsWithError()[0];

    const field = document.getElementById(fieldId);
    if (field) {
      field.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const handleSave = async () => {
    if (form.isValid()) {
      await store.saveData(form.values);
    }

    if (store.getFieldsWithError().length) {
      scrollToError();
    } else {
      await store.runSavedScenario();
      handleRedirect();
    }
  };
  const handlePreview = async () => {
    if (form.isValid()) {
      await store.saveData(form.values);
    }

    if (store.getFieldsWithError().length) {
      scrollToError();
    } else {
      await store.runPreviewScenario();
      handleRedirect();
    }
  };

  const handleCancel = async () => {
    await store.runCancelScenario();
    handleRedirect();
  };

  const handleShare = async () => {
    if (form.isValid()) {
      await store.saveData(form.values);
    }

    if (store.getFieldsWithError().length) {
      scrollToError();
    } else {
      await store.runShareScenario();
      handleRedirect();
    }
  };

  return (
    <div className={styles.buttonBlock}>
      {store.saveStatus === "error" && (
        <div className={styles.errorView}>
          <ErrorView />
        </div>
      )}
      {!!store.getValidationError("token") && (
        <div className={styles.errorView}>
          <ErrorView>Authentication error</ErrorView>
        </div>
      )}
      <Button
        className={classNames(styles.buttonPrimary, styles.buttonSave)}
        disabled={form.hasErrors || !!store.getValidationError("imageSize")}
        onClick={handleShare}
      >
        Share payment link
      </Button>
      <Button
        className={classNames(styles.buttonSave)}
        disabled={form.hasErrors || !!store.getValidationError("imageSize")}
        onClick={handleSave}
        type="secondary"
      >
        Save and close
      </Button>
      <Button
        className={classNames(styles.buttonSave)}
        disabled={form.hasErrors || !!store.getValidationError("imageSize")}
        onClick={handlePreview}
        type="secondary"
      >
        Preview page
      </Button>

      <TextButton className={styles.buttonCancel} onClick={handleCancel} text="Cancel" type="text" />
    </div>
  );
});
