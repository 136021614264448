import Spin from "antd/lib/spin";
import React, { FC } from "react";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

type LoaderProps = {
  style?: CSSRule;
  className?: string;
};

export const Loader: FC<LoaderProps> = ({ style, className }) => (
  <div className={className}>
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
            color: "#fff",
            ...style,
          }}
          spin
        />
      }
    />
  </div>
);
