const checkEmail = (email?: string): boolean => {
  if (!email) {
    return true;
  }
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const checkLink = (link?: string): boolean => {
  if (!link) return true;
  let url;

  try {
    url = new URL(link);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const contactsDetailsValidationConfig = {
  contactEmail: {
    rules: [
      { check: (value: string): boolean => checkEmail(value), message: "Invalid email. ex: yourbusiness@email.com" },
    ],
  },
  contactWebsite: {
    rules: [{ check: (value: string): boolean => checkLink(value), message: "Invalid link. ex: https://example.com" }],
  },
};

export const socialMediaDetailsValidationConfig = {
  instagramLink: {
    rules: [{ check: (value: string): boolean => checkLink(value), message: "Invalid link. ex: https://example.com" }],
  },
  facebookLink: {
    rules: [{ check: (value: string): boolean => checkLink(value), message: "Invalid link. ex: https://example.com" }],
  },
  linkedinLink: {
    rules: [{ check: (value: string): boolean => checkLink(value), message: "Invalid link. ex: https://example.com" }],
  },
};

export const validationConfig = {
  ...contactsDetailsValidationConfig,
  ...socialMediaDetailsValidationConfig,
};

export const validationViewPageConfig = {
  amount: {
    rules: [{ check: (value: string): boolean => !!value, message: "Required" }],
  },
  email: {
    rules: [
      { check: (value: string): boolean => !!value && checkEmail(value), message: "Invalid email. ex: yourbusiness@email.com" },
    ],
  },
  name: {
    rules: [
      { check: (value: string): boolean => !!value && value.length >= 3, message: "Required" },
    ],
  },
  agreement: {
    rules: [{ check: (value: string): boolean => !!value, message: "Required" }],
  },
};

export const defaultMaxAmount = 100000;
const minAmount = 0.01;

export function validateAmount(maxAmountFromServer?: string, formAmount?: string): "max" | "min" | null {
  let maxAmount;
  if (!maxAmountFromServer) {
    maxAmount = defaultMaxAmount;
  } else {
    maxAmount = Number(maxAmountFromServer);
  }

  const amount = Number(formAmount);

  if (amount < minAmount) {
    return "min";
  }

  if (amount > maxAmount) {
    return "max";
  }

  return null;
}
