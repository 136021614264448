import { Route } from "react-router-dom";
import React from "react";
import { Payment } from "./payment";

export const paymentRoutes = (
  <>
    <Route path="providers-selection" element={<Payment paymentType="banks" />} />
    <Route path="card-details" element={<Payment paymentType="card" />} />
  </>
);
