import React, { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { FormState, isEmailValid, isNameValid, normalizeValue, validateValue } from "./formStore";
import annaLogo from "../../../../resources/annaLogo.svg";
import catImage from "../../../../resources/luckyCat.svg";
import styles from "./Form.module.scss";
import radioButtonStyles from "../../../../components/radioButton/RadioButton.module.scss";
import { RadioButton } from "../../../../components/radioButton/radioButton";
import { SideContent } from "../../../../components/sideContent/sideContent";
import { useModal, useMount } from "../../../../helpers/reactHooks";
import { ErrorState } from "../../../../helpers/error";
import { Status } from "../../../../components/status/status";
import { gaEventName, gaSendUserEvent } from "../../../../api/analytics.api.client";
import { PaymentTemplate } from "../../../../types/payment";
import { useServiceFactory, useServices } from "../../../../app/appServices";
import { Loader } from "../../../../components/loader/loader";
import { CardPaymentNotAllowedModal } from "../../../../components/cardPaymentNotAllowed/cardPaymentNotAllowedModal";
import { PaymentInfo } from "../../../obPaymentPage/obPaymentPageView/headerViewSection/headerViewSection";

type FormPageProps = {
  template: PaymentTemplate;
};

const minAmountBankPayment = 0.01;
const minAmountCardPayment = 1;

export const Form: FC<FormPageProps> = observer(({ template }) => {
  const formStore = useServiceFactory((x) => x.createPaymentLinkFormStore());
  const analytics = useServices().annaAnalyticsClient;
  const [agreement, toggleAgreement] = useState<boolean>(false);
  const modal = useModal();
  const payBankButton = useRef(null);
  const payCardButton = useRef(null);
  const inputField = useRef(null);

  useMount(async () => {
    await analytics.postAnnaAnalyticsEvent("form-screen", {
      payment_template_id: template.templateId,
      product_id: template.productId
    });
    await formStore.load(template);
    window.scrollTo(0, 1);
  });

  useEffect(() => {
    if (formStore.redirectUrl) {
      window.location.replace(formStore.redirectUrl);
    }
  }, [formStore.redirectUrl]);

  const renderAmountBlock = (form: FormState) =>
    form.isAmountReadOnly ? (
      <div className={classNames(styles.blockCurrency, !form.isDescriptionReadOnly && styles.withBorder)}>
        £{form.formattedAmount}
      </div>
    ) : (
      <>
        <div className={classNames(styles.inputContainer, styles.amountField)}>
          <div className={styles.inputCurrency}>£</div>
          <input
            inputMode="decimal"
            className={styles.input}
            tabIndex={1}
            type="text"
            ref={inputField}
            onChange={(event) => formStore.onAmountChange(form, event.target.value, false)}
            onBlur={(event) => formStore.onAmountChange(form, event.target.value, true)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                (event.target as HTMLElement).blur();
                if (payBankButton.current) {
                  (payBankButton.current as HTMLElement).focus();
                }
              }
            }}
            placeholder="Enter amount"
            value={form.formattedAmount}
            readOnly={form.isAmountReadOnly}
          />
        </div>
        <div className={styles.inputErrorContainer}>
          {validateValue(template, formStore.state, minAmountBankPayment) === "max" && (
            <div className={styles.inputError}>
              {`* Sorry, our maximum for one payment is £${normalizeValue(template.maxAmount)}`}
            </div>
          )}
        </div>
      </>
    );

  const renderDescriptionBlock = (form: FormState) =>
    form.isDescriptionReadOnly ? (
      <div className={styles.blockDescription}>{`For ${form.description}`}</div>
    ) : (
      <div className={styles.inputContainer}>
        <input
          className={styles.inputWithPadding}
          tabIndex={2}
          type="text"
          placeholder="What’s this payment for? (optional)"
          onChange={(event) => formStore.onDescriptionChange(form, event.target.value)}
          readOnly={form.isDescriptionReadOnly}
          value={form.description}
        />
      </div>
    );

  const renderNameField = (form: FormState) => (
    <div className={classNames(styles.inputContainer)}>
      <input
        className={styles.inputWithPadding}
        tabIndex={3}
        inputMode="text"
        onChange={(event) => formStore.onNameChange(form, event.target.value)}
        placeholder="Your name"
        value={form.name}
      />
    </div>
  );

  const renderEmailField = (form: FormState) => (
    <>
      <div className={classNames(styles.inputContainer, styles.email)}>
        <input
          className={styles.inputWithPadding}
          tabIndex={4}
          inputMode="email"
          onChange={(event) => formStore.onEmailChange(form, event.target.value)}
          placeholder="Your email address"
          value={form.email}
        />
      </div>
      <div className={styles.inputErrorContainer}>
        {!isEmailValid(form.email) && <div className={styles.inputError}>* Should be a valid e-mail address</div>}
      </div>
    </>
  );

  const renderAgreementBlock = () => (
    <div>
      <RadioButton
        className={radioButtonStyles.agreeRadioButton}
        onChange={() => {
          toggleAgreement(!agreement);
        }}
        label={
          <>
            I agree to{" "}
            <a href="https://anna.money/privacy-policy" target="_blank" rel="noreferrer">
              ANNA’s privacy policy
            </a>
          </>
        }
        checked={agreement}
      />
    </div>
  );

  const isAllowSubmit = (form: FormState, minAmount: number): boolean =>
    !validateValue(template, formStore.state, minAmount) && form.name && isNameValid(form.name) && form.email && isEmailValid(form.email) && agreement;

  const handleMakeCardPaymentButtonClick = async (form: FormState): Promise<void> => {
    if (!formStore.inProgress && isAllowSubmit(form, minAmountCardPayment)) {
      gaSendUserEvent(gaEventName.clickConnectByStripe);
      await formStore.makeCardTransaction(form, template, modal.open);
    }
  };

  const handleMakeBankPaymentButtonClick = async (form: FormState): Promise<void> => {
    if (!formStore.inProgress && isAllowSubmit(form, minAmountBankPayment)) {
      gaSendUserEvent(gaEventName.clickConnectByTrueLayer);
      await formStore.makeBankTransaction(form, template);
    }
  };

  const renderSubmitButtons = (form: FormState) => (
    <>
      {template.allowBankPayments && (
        <div
          className={classNames(styles.payButton, !isAllowSubmit(form, minAmountBankPayment) && styles.disabled)}
          ref={payBankButton}
          tabIndex={5}
          onClick={() => handleMakeBankPaymentButtonClick(form)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              void handleMakeBankPaymentButtonClick(form);
            }
          }}
        >
          {formStore.inProgress ? <Loader /> : "Pay by bank transfer"}
        </div>
      )}

      {template.showPayByCard && (
        <div
          className={classNames(
            styles.payButton,
            styles.payByCard,
            !isAllowSubmit(form, minAmountCardPayment) && styles.disabled
          )}
          ref={payCardButton}
          tabIndex={6}
          onClick={() => handleMakeCardPaymentButtonClick(form)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              void handleMakeCardPaymentButtonClick(form);
            }
          }}
        >
          {formStore.inProgress ? <Loader /> : "Pay by card"}
        </div>
      )}
    </>
  );

  const renderFullReadOnlyDescriptionAmountState = (state, thankYouMessage) => (
    <>
      {state.isAmountReadOnly ? (
        <>
          {renderAmountBlock(state)}
          {renderDescriptionBlock(state)}
          <div className={classNames(styles.thankYou)}>{thankYouMessage}</div>
        </>
      ) : (
        <>
          <div className={styles.thankYou}>{thankYouMessage}</div>
          {renderAmountBlock(state)}
          {renderDescriptionBlock(state)}
        </>
      )}
    </>
  );

  const renderNotFullReadOnlyDescriptionAmountState = (state, thankYouMessage) => (
    <>
      {state.isDescriptionReadOnly ? (
        <>
          {renderDescriptionBlock(state)}
          <div className={styles.thankYou}>{thankYouMessage}</div>
          {renderAmountBlock(state)}
        </>
      ) : (
        <>
          {renderAmountBlock(state)}
          <div className={styles.thankYou}>{thankYouMessage}</div>
          {renderDescriptionBlock(state)}
        </>
      )}
    </>
  );

  const renderContent = () => {
    const {
      logoUrl,
      companyTitle,
      beneficiaryName,
      thankYouMessage,
      beneficiaryAccountNumber,
      beneficiarySortCode
    } = template;
    if (formStore.state === "loading") {
      return <div>Loading...</div>;
    }

    if (formStore.state instanceof ErrorState) {
      return <Status type="error" title="Something goes wrong.." text={formStore.state.message} canReload />;
    }

    const renderAnnaLogo = !logoUrl;
    const logo = logoUrl || catImage;
    const thankYouMessageBlock = thankYouMessage || "Thank you for your business";
    const sortCodeBlock = beneficiarySortCode ? `${beneficiarySortCode.match(/\d{2}/gi)?.join("-")}` : null;

    const { isDescriptionReadOnly, isAmountReadOnly } = formStore.state;
    const fullReadOnlyDescriptionAmountState = isDescriptionReadOnly && isAmountReadOnly;
    const fullNotReadOnlyDescriptionAmountState = !isDescriptionReadOnly && !isAmountReadOnly;

    return (
      <div className={classNames(styles.app, formStore.inProgress && styles.progress)}>
        <div className={styles.content}>
          <div className={styles.mainContent}>
            {renderAnnaLogo && (
              <div className={styles.header}>
                <a href="https://anna.money">
                  <img className={styles.headerLogo} src={annaLogo} alt="ANNA logo" />
                </a>
              </div>
            )}
            <div className={styles.mainContentCenter}>
              <img src={logo} className={styles.contentIllustration} alt="" />
              <div className={styles.companyName}>{`Pay ${companyTitle}`}</div>
              <div className={styles.paymentInfo}>
                <PaymentInfo
                  beneficiaryName={beneficiaryName}
                  beneficiarySortCode={sortCodeBlock}
                  beneficiaryAccountNumber={beneficiaryAccountNumber}
                />
              </div>
              {fullReadOnlyDescriptionAmountState || fullNotReadOnlyDescriptionAmountState ? (
                <>{renderFullReadOnlyDescriptionAmountState(formStore.state, thankYouMessageBlock)}</>
              ) : (
                <>{renderNotFullReadOnlyDescriptionAmountState(formStore.state, thankYouMessageBlock)}</>
              )}
              {renderNameField(formStore.state)}
              {renderEmailField(formStore.state)}
              {renderAgreementBlock()}
              {renderSubmitButtons(formStore.state)}
              <div className={styles.faqLink}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => gaSendUserEvent(gaEventName.clickFaq)}
                  href="https://anna.money/blog/updates/your-anna-payment-link-a-new-handy-way-to-accept-payments#p2"
                >
                  Learn more about Payments by ANNA
                </a>
                <div className={styles.secondLine}>
                  or&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => gaSendUserEvent(gaEventName.clickStartAcceptPayments)}
                    href="https://go.anna.money/accept-payments/?utm_medium=organic&utm_source=clients_payment_link"
                  >
                    start accepting payments
                  </a>
                </div>
              </div>
            </div>
          </div>
          <SideContent utmSource={template.templateId} utmCampaign="payment_link_banner" />
        </div>
        <CardPaymentNotAllowedModal visible={modal.visible} onClose={modal.close} name={beneficiaryName} />
      </div>
    );
  };

  return <>{renderContent()}</>;
});
