import { Modal } from "antd";
import React, { FC } from "react";

import styles from "./CardPaymentNotAllowedModal.module.scss";

type Props = {
  name: string;
  visible: boolean;
  onClose: () => void;
};

export const CardPaymentNotAllowedModal: FC<Props> = ({ name, visible, onClose }) => (
  <Modal
    className={styles.modal}
    visible={visible}
    centered
    maskClosable={false}
    closable
    footer={null}
    width={500}
    onCancel={onClose}
  >
    <div className={styles.message}>
      <div className={styles.textHeader}>Pay by card isn&apos;t available</div>
      {name} doesn&apos;t accept card payments yet. <br /> You can still make the payment, just choose &apos;Pay by bank
      transfer&apos;
    </div>
    <div className={styles.buttons}>
      <div className={styles.closeButton} onClick={onClose}>
        Ok, got it
      </div>
    </div>
  </Modal>
);
