import React, { ChangeEvent, useEffect, useRef, VFC } from "react";
import { FieldError, FieldsGroup } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import styles from "./amountSection.module.scss";
import { FormSectionType } from "../../obPaymentPageForm";
import { currencySymbol } from "../../../../helpers/constants";
import { Toggle } from "../../../../components/toggle/toggle";
import { Input } from "../../../../components/input/input";
import { Dropdown } from "../../../../components/dropdown/dropdown";
import { Label } from "../../../../components/label/label";

export const AmountSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const quantityInputRef = useRef(null);
  const { switchedOAmount } = form.values;

  const handleChangeToggle = (value) => {
    form.onChange("switchedOAmount", value);
    if (!value) {
      form.onChange("amount", "");
      form.onChange("paymentLinkDescription", "");
    }
  };

  useEffect(() => {
    quantityInputRef?.current?.addEventListener("wheel", (e) => e.preventDefault());
  }, [quantityInputRef]);

  const withMargin = !!form.getValue("logo") && store.firstUse && !store.getValidationError("imageSize");

  return (
    <div className={classNames(styles.section, styles.withSeparator, withMargin && styles.withMargin)}>
      <div className={styles.amountSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            <span className={styles.sectionSubtitle}>How much to pay</span>
            <span className={styles.sectionDescription}>
              Include the amount customers need to pay you and an optional description
            </span>
          </div>
          <Toggle value={switchedOAmount} onChange={handleChangeToggle} />
        </div>
        {switchedOAmount && (
          <>
            <div className={styles.fieldRow}>
              <Label labelText="Amount" />
              <FieldsGroup className={styles.fieldGroup}>
                <Dropdown
                  className={styles.currencyField}
                  value={form.getValue("currency")}
                  hasError={!!form.getError("amount")}
                  options={[
                    { value: "GBP", label: currencySymbol.GBP },
                    { value: "USD", label: currencySymbol.USD },
                    { value: "EUR", label: currencySymbol.EUR },
                  ]}
                  onChange={(value) => form.onChange("currency", value)}
                />
                <Input
                  className={styles.field}
                  ref={quantityInputRef}
                  type="number"
                  min={0}
                  hasError={!!form.getError("amount")}
                  placeholder="0.00"
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    form.onChange("amount", e.target.value);
                    store.clearValidationError("amount");
                  }}
                  value={form.getValue("amount")}
                />
              </FieldsGroup>
              <FieldError errorText={form.getError("amount") || store.getValidationError("amount")} />
            </div>
            <div className={styles.fieldRow}>
              <Input
                label="What is this for?"
                className={styles.field}
                placeholder="Optional"
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  form.onChange("paymentLinkDescription", e.target.value);
                }}
                value={form.getValue("paymentLinkDescription")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});
