import React, { FC } from "react";
import styles from "./closeView.module.scss";
import annaLogo from "../../../../resources/annaLogo.svg";
import happyBird from "../../../../components/status/resources/happy_bird_RGB.svg";

export const CloseView: FC = () => (
  <div className={styles.status}>
    <div className={styles.centerBlock}>
      <div className={styles.logo}>
        <img src={annaLogo} alt="logo" />
      </div>
      <div className={styles.illustration}>
        <img src={happyBird} alt="illustration" />
      </div>
      <div className={styles.text}>Now you can close this window</div>
    </div>
  </div>
);
