import { makeAutoObservable } from "mobx";
import axios from "axios";
import { InvoiceApiClient } from "../../api/invoice.api.client";
import { ErrorState } from "../../helpers/error";
import { InvoiceRaw, InvoiceStatus } from "../../types/invoice";
import { PaymentStatus } from "../../types/payment";
import catImage from "../../resources/luckyCat.svg";
import { downloadFile } from "../../helpers/file";

type Invoice = {
  total: string;
  status: InvoiceStatus;
  textNumber: string;
  companyLogo: string;
  companyTitle: string;
  invoicePdf: string;
  paymentStatus: Nullable<PaymentStatus>;
  paymentUrl: Nullable<string>;
  dueDate: string;
  currency: Nullable<string>;
};

export type InvoiceLinkData = {
  paymentId?: string;
  secureId: string;
  invoice: Invoice;
};

type InvoiceLinkState = "loading" | ErrorState | InvoiceLinkData;

function toInvoice(invoice: InvoiceRaw): Invoice {
  return {
    total: invoice.total,
    status: invoice.status as InvoiceStatus,
    textNumber: invoice.text_number,
    companyLogo: invoice.company.logo ?? catImage,
    companyTitle: invoice.company.title,
    invoicePdf: invoice.pdf_url,
    paymentStatus: invoice.payment?.status,
    paymentUrl: invoice.payment_url,
    dueDate: invoice.due_date,
    currency: invoice.currency,
  };
}

export class InvoiceLinkStore {
  private readonly _api: InvoiceApiClient;

  private _state: InvoiceLinkState = "loading";

  constructor(client: InvoiceApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): InvoiceLinkState {
    return this._state;
  }

  set state(state: InvoiceLinkState) {
    this._state = state;
  }

  async load(secureId: string, paymentId?: string): Promise<void> {
    this.state = "loading";
    try {
      const invoiceRaw = await this._api.getInvoiceBySecureId(secureId);
      this.state = {
        secureId,
        paymentId,
        invoice: toInvoice(invoiceRaw),
      };
    } catch (e) {
      this.state = new ErrorState("Failed to request the invoice details");
      throw e;
    }
  }
}

export async function downloadInvoice(pdfUrl: string, number: string): Promise<void> {
  const resp = await axios({ method: "get", url: pdfUrl, responseType: "blob" });
  const url = URL.createObjectURL(resp.data);
  try {
    downloadFile(url, `${number}.pdf`);
  } finally {
    URL.revokeObjectURL(url);
  }
}
