import React, { ChangeEvent, useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton } from "@anna-money/anna-web-ui";
import classnames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import styles from "./socialMediaSection.module.scss";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "../../../../icons/icons";
import { Toggle } from "../../../../components/toggle/toggle";
import { useFactory } from "../../../../helpers/reactHooks";
import { Form } from "../../../../components/form/form";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { Input } from "../../../../components/input/input";
import { socialMediaDetailsValidationConfig } from "../../validation";

export const SocialMedialSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const { instagramLink, facebookLink, linkedinLink, switchedOnSocialMedia } = form.values;
  const modalForm = useFactory(
    () =>
      new Form({ instagramLink, facebookLink, linkedinLink, switchedOnSocialMedia }, socialMediaDetailsValidationConfig)
  );

  const [isFilled, setFilled] = useState(!!instagramLink || !!facebookLink || !!linkedinLink);
  const [editMode, setEditMode] = useState(!isFilled);

  const handleChangeToggle = (value: boolean, formData): void => {
    formData.onChange("switchedOnSocialMedia", value);
    if (!value) {
      formData.onChange("instagramLink", "");
      formData.onChange("facebookLink", "");
      formData.onChange("linkedinLink", "");
    }
  };

  const renderViewContent = () => (
    <div className={styles.viewSection}>
      <div className={styles.contactDetailsSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionViewTitle}>
            <span className={styles.sectionSubtitle}>Social media</span>
          </div>

          <IconButton icon="actionEdit" iconColor="themeGreen" size="s" onClick={() => setEditMode(true)} />
        </div>
        <div className={styles.sectionViewContent}>
          {instagramLink && (
            <div className={styles.rowContent}>
              <InstagramIcon height="17" width="17" />
              <span className={styles.text}>{instagramLink}</span>
            </div>
          )}
          {facebookLink && (
            <div className={styles.rowContent}>
              <FacebookIcon height="17" width="17" />
              <span className={styles.text}>{facebookLink}</span>
            </div>
          )}
          {linkedinLink && (
            <div className={styles.rowContent}>
              <LinkedinIcon width="17" height="17" />
              <span className={styles.text}>{linkedinLink}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderForm = (formData) => (
    <div className={styles.amountSection}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>
          <span className={styles.sectionSubtitle}>Social media</span>
          <span className={styles.sectionDescription}>Include your social media links</span>
        </div>
        <Toggle
          value={formData.getValue("switchedOnSocialMedia")}
          onChange={(value) => handleChangeToggle(value, formData)}
        />
      </div>

      {formData.getValue("switchedOnSocialMedia") && (
        <>
          <div className={styles.rowContent}>
            <InstagramIcon height="26" width="26" />
            <Input
              className={styles.field}
              id="instagramLink"
              placeholder="Instagram profile"
              autoCapitalize="none"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                formData.onChange("instagramLink", e.target.value);
                store.clearValidationError("instagramLink");
              }}
              value={formData.getValue("instagramLink")}
              error={formData.getError("instagramLink") || store.getValidationError("instagramLink")}
            />
          </div>
          <div className={styles.rowContent}>
            <FacebookIcon height="26" width="26" />
            <Input
              className={styles.field}
              placeholder="Facebook profile"
              id="facebookLink"
              autoCapitalize="none"
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                formData.onChange("facebookLink", e.target.value);
                store.clearValidationError("facebookLink");
              }}
              value={formData.getValue("facebookLink")}
              error={formData.getError("facebookLink") || store.getValidationError("facebookLink")}
            />
          </div>
          <div className={styles.rowContent}>
            <LinkedinIcon height="26" width="26" />
            <Input
              className={styles.field}
              placeholder="LinkedIn profile"
              autoCapitalize="none"
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                formData.onChange("linkedinLink", e.target.value);
                store.clearValidationError("linkedinLink");
              }}
              value={formData.getValue("linkedinLink")}
              error={formData.getError("linkedinLink") || store.getValidationError("linkedinLink")}
            />
          </div>
        </>
      )}
    </div>
  );

  const handleCancelEditModal = () => {
    setEditMode(false);
    modalForm.onMultipleChange([
      { field: "linkedinLink", value: form.getValue("linkedinLink") },
      { field: "facebookLink", value: form.getValue("facebookLink") },
      { field: "instagramLink", value: form.getValue("instagramLink") },
      { field: "switchedOnSocialMedia", value: form.getValue("switchedOnSocialMedia") },
    ]);
  };

  const handleSaveEditModal = () => {
    if (!modalForm.isValid()) {
      return;
    }

    setEditMode(false);
    if (
      ![
        modalForm.getValue("linkedinLink"),
        modalForm.getValue("facebookLink"),
        modalForm.getValue("instagramLink"),
      ].filter(Boolean).length
    ) {
      form.onChange("switchedOnSocialMedia", false);
      setFilled(false);
    } else {
      setFilled(true);
    }
    form.onMultipleChange([
      { field: "linkedinLink", value: modalForm.getValue("linkedinLink") },
      { field: "facebookLink", value: modalForm.getValue("facebookLink") },
      { field: "instagramLink", value: modalForm.getValue("instagramLink") },
      { field: "switchedOnSocialMedia", value: modalForm.getValue("switchedOnSocialMedia") },
    ]);
  };

  if (store.firstUse || !isFilled) {
    return (
      <div className={classnames(styles.section, styles.withSeparator)}>
        <div>{renderForm(form)}</div>
      </div>
    );
  }

  return (
    <>
      {renderViewContent()}
      <EditFormModalSection open={editMode} onCancel={handleCancelEditModal} onSave={handleSaveEditModal}>
        <div className={styles.section}>
          <div>{renderForm(modalForm)}</div>
        </div>
      </EditFormModalSection>
    </>
  );
});
