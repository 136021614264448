import { AnnaResponseRaw, Api } from "./api";
import { PaymentInfo, PaymentInfoRaw, PaymentRequestRaw, PaymentTemplateRaw } from "../types/payment";
import {
  FileData,
  PaymentPage,
  PaymentPageCreate,
  PaymentPageRaw,
  RunPaymentPageSkill,
  UploadDescriptor,
} from "../types/paymentPage";
import { convertResponse } from "../helpers/serialization";

export class PaymentApiClient {
  private _api: Api;

  constructor(api: Api) {
    this._api = api;
  }

  async getTransactionTemplate(paymentTemplateId: string, productId?: string): Promise<PaymentTemplateRaw> {
    const params = new URLSearchParams();
    if (productId) {
      params.append("product_id", productId);
    }

    const queryString = params.toString() ? `?${params.toString()}` : "";

    const response = await this._api.get<AnnaResponseRaw<PaymentTemplateRaw>>(
      `/api/openbanking/i/payment-link/${paymentTemplateId}${queryString}`
    );

    return response.data.data;
  }

  async getTransactionInfo(paymentId: string): Promise<PaymentInfoRaw> {
    const response = await this._api.get<AnnaResponseRaw<PaymentInfoRaw>>(
      `/api/openbanking/i/payment-requests/${paymentId}`
    );

    return response.data.data;
  }

  async makePayment(
    paymentTemplateId: Nullable<string>,
    amount: string,
    description: string,
    name: string,
    email: string,
    productId: Nullable<string>
  ): Promise<{ payment_request_id: string }> {
    const response = await this._api.post<AnnaResponseRaw<PaymentRequestRaw>>(
      `/api/openbanking/i/v2/payment-link/${paymentTemplateId}`,
      {
        amount,
        description,
        name,
        email,
        product_id: productId,
      }
    );

    return response.data.data;
  }

  async registerNotAllowedCardPaymentAttempt(paymentTemplateId: Nullable<string>): Promise<void> {
    await this._api.post(
      `/api/openbanking/i/payment-link/${paymentTemplateId}/not-allowed-attempt-to-card-payment`,
      {}
    );
  }

  async createPaymentPage(data: PaymentPageCreate): Promise<PaymentPageRaw> {
    const response = await this._api.post<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages`, data);
    return response.data.data;
  }

  async sharePaymentPage(data: RunPaymentPageSkill): Promise<void> {
    await this._api.post<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages/share`, data);
  }

  async previewPaymentPage(data: RunPaymentPageSkill): Promise<void> {
    await this._api.post<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages/preview`, data);
  }

  async sendFollowUpSkill(data: RunPaymentPageSkill): Promise<void> {
    await this._api.post<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages/saved`, data);
  }

  async cancelEditingPaymentPage(data: RunPaymentPageSkill): Promise<void> {
    await this._api.post<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages/cancel`, data);
  }

  async updatePaymentPage(id: string, data: PaymentPageCreate): Promise<PaymentPageRaw> {
    const response = await this._api.put<AnnaResponseRaw<PaymentPageRaw>>(
      `/api/openbanking/i/payment-pages/${id}`,
      data
    );
    return response.data.data;
  }

  async uploadFile(token: string, file: File): Promise<FileData> {
    const uploadDescriptorResponse = await this._api.post<AnnaResponseRaw<UploadDescriptor>>(
      `/api/openbanking/i/payment-pages/files/init`,
      { token }
    );
    const uploadDescriptor = uploadDescriptorResponse.data.data;
    await this._api.put(uploadDescriptor.signed_url, file, { headers: { "Content-Type": "application/octet-stream" } });

    const fileDataResponse = await this._api.post<AnnaResponseRaw<FileData>>(
      `/api/openbanking/i/payment-pages/files/finish`,
      { token, storage_file_path: uploadDescriptor.file_path, original_file_name: file.name }
    );

    return fileDataResponse.data.data;
  }

  async getPaymentPage(id: string): Promise<PaymentPage> {
    const response = await this._api.get<AnnaResponseRaw<PaymentPageRaw>>(`/api/openbanking/i/payment-pages/${id}`);
    return convertResponse<PaymentPage>(response.data.data);
  }

  async getLogo(url: string): Promise<Blob> {
    const result = await this._api.getFile(url);
    return result;
  }
}

export function createPaymentDetails(data: PaymentInfoRaw): PaymentInfo {
  return {
    id: data.id,
    backUrl: data.back_url || "",
    logoUrl: data.logo_url || "",
    toAccountName: data.to_account_name,
    amount: data.amount,
    description: data.description || "",
    reference: data.reference || "",
    openbankingPaymentRequest: {
      id: data.openbanking_payment_request.id,
      token: data.openbanking_payment_request.token,
      toAccountNumber: data.openbanking_payment_request.to_account_number || "",
      toAccountSortCode: data.openbanking_payment_request.to_sort_code || "",
    },
    stripePaymentIntent: {
      clientSecret: data.stripe_payment_intent?.client_secret || "",
      accountId: data.stripe_payment_intent?.account_id || "",
    },
    currency: data.currency,
  };
}
