import React, { useEffect, useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { Loader, WindowManager } from "@anna-money/anna-web-ui";
import { useLocation, useParams } from "react-router";
import classNames from "classnames";
import { useServiceFactory } from "../../app/appServices";
import { ObPaymentPageFormStore } from "./obPaymentPageFormStore";
import styles from "./obPaymentPage.module.scss";
import { AmountSection } from "./components/amountSection/amountSection";
import { HeaderSection } from "./components/headerSection/headerSection";
import { CompanyLogoSection } from "./components/companyLogoSection/companyLogoSection";
import { AboutSection } from "./components/aboutSection/aboutSection";
import { ContactDetailsSection } from "./components/contactDetailsSection/contactDetailsSection";
import { SocialMedialSection } from "./components/socialMediaSection/socialMediaSection";
import { OpeningHoursSection } from "./components/openingHoursSection/openingHoursSection";
import { SubmitSection } from "./components/submitSection/submitSection";
import { BusinessLocationSection } from "./components/businessLocationSection/businessLocationSection";
import { BusinessDetailsSection } from "./components/businessDetailsSection/businessDetailsSection";
import { Form } from "../../components/form/form";
import { PaymentPageForm } from "../../types/paymentPage";
import { Status } from "../../components/status/status";

export type FormSectionType = {
  form: Form<PaymentPageForm>;
  store: ObPaymentPageFormStore;
};

export const ObPaymentPageForm: VFC = observer(() => {
  const store = useServiceFactory((x) => x.createObPaymentPageFormStore());
  const params = useParams();
  const [sections, setSections] = useState([]);
  const location = useLocation();

  useEffect(() => {
    store.setToken(new URLSearchParams(location.search).get("token"));
    void store.load(params.id);
  }, []);

  useEffect(() => {
    if (store.state !== "error" && store.state !== "loading") {
      const {
        logo,
        address,
        googleMapsPlaceId,
        paymentPageDescription,
        instagramLink,
        facebookLink,
        linkedinLink,
        workingHours,
        contactEmail,
        contactWebsite,
        contactPhone,
      } = store.state.form.values;

      const initialSections = [
        {
          component: CompanyLogoSection,
          filled: !!logo,
          id: "CompanyLogoSection",
          props: { modalForm: store.state.modalHeaderForm },
        },
        {
          component: BusinessLocationSection,
          filled: !!address && !!googleMapsPlaceId,
          id: "BusinessLocationSection",
          props: {},
        },
        {
          component: AboutSection,
          filled: !!paymentPageDescription,
          id: "AboutSection",
          props: {},
        },
        {
          component: ContactDetailsSection,
          filled: !!contactEmail || !!contactWebsite || !!contactPhone,
          id: "ContactDetailsSection",
          props: {},
        },
        {
          component: OpeningHoursSection,
          filled: !!workingHours?.length,
          id: "OpeningHoursSection",
          props: {},
        },
        {
          component: SocialMedialSection,
          filled: !!instagramLink || !!facebookLink || !!linkedinLink,
          id: "SocialMedialSection",
          props: {},
        },
      ];

      if (store.firstUse) {
        setSections(initialSections);
      } else {
        setSections(initialSections.sort((a, b) => Number(b.filled) - Number(a.filled)));
      }
    }
  }, [store.state]);

  if (store.state === "loading") {
    return <Loader />;
  }

  if (store.state === "error") {
    return <Status type="error" title="Something goes wrong..." canReload />;
  }
  const { form } = store.state;
  return (
    <div className={styles.wrapper}>
      <WindowManager>
        <HeaderSection form={form} store={store} modalForm={store.state.modalHeaderForm} />
        {form.getValue("beneficiaryName") && (
          <div
            className={classNames(
              styles.section,
              styles.withSeparator,
              form.getValue("logo") && !store.getValidationError("imageSize") && styles.withMargin
            )}
          >
            <span className={styles.beneficiaryName}>{form.getValue("beneficiaryName")}</span>
            <span className={styles.businessType}>{form.getValue("businessType")}</span>
          </div>
        )}
        <div className={styles.fieldsSection}>
          <AmountSection form={form} store={store} />
          {!store.firstUse && <BusinessDetailsSection form={form} />}
          {sections.map(({ id, component: SectionComponent, props }) => (
            <SectionComponent key={id} form={form} store={store} {...props} />
          ))}
          <SubmitSection form={form} store={store} />
        </div>
      </WindowManager>
    </div>
  );
});
