import { AnnaResponseRaw, Api } from "./api";
import { Provider } from "../types/banks";

export class ProvidersApiClient {
  private _api: Api;

  constructor(api: Api) {
    this._api = api;
  }

  async getProviders(paymentRequestId: string): Promise<{ items: Provider[] }> {
    const response = await this._api.get<AnnaResponseRaw<{ items: Provider[] }>>(
      `/api/openbanking/i/payment-providers?payment_request_id=${paymentRequestId}`
    );

    return response.data.data;
  }

  async selectProvider(paymentRequestId: string, token: string, providerId: string): Promise<{ auth_url: string }> {
    const response = await this._api.post<AnnaResponseRaw<{ auth_url: string }>>(
      `/api/openbanking/i/payment-requests/${paymentRequestId}/provider`,
      {
        token,
        provider_id: providerId,
      }
    );

    return response.data.data;
  }
}
