import { makeAutoObservable } from "mobx";
import { ErrorState } from "../../../helpers/error";

type StripeConfig = { apiKey: string };
type CardPageState = "loading" | ErrorState | StripeConfig;

export class CardPageStore {
  private _state: CardPageState = "loading";

  private readonly _key: Nullable<string>;

  constructor(key: Nullable<string>) {
    makeAutoObservable(this);
    this._key = key;
  }

  get state(): CardPageState {
    return this._state;
  }

  private set state(state: CardPageState) {
    this._state = state;
  }

  async load(): Promise<void> {
    this.state = "loading";
    if (this._key) {
      this.state = { apiKey: this._key as string };
    } else {
      this.state = new ErrorState("Stripe api key is incorrect");
    }
  }
}
