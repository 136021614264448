export function downloadFile(src: string, name: string): boolean {
  const link = document.createElement("a");
  link.href = src;
  link.download = name;
  document.body.appendChild(link);

  if (document.createEvent) {
    const e = document.createEvent("MouseEvents");
    e.initEvent("click", true, true);
    link.dispatchEvent(e);
    return true;
  }

  document.body.removeChild(link);

  return false;
}
