import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { SPACE_KEY } from "../../helpers/keyboard";
import { CheckIcon } from "../../icons/icons";
import styles from "./RadioButton.module.scss";

type RadioButtonProps = {
  label: ReactNode;
  checked: boolean;
  errorMessage?: string;
  onChange: () => void;
  className?: string;
};

export const RadioButton: FC<RadioButtonProps> = ({ label, checked, errorMessage, onChange, className }) => {
  const handleCheckBoxEnter = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.keyCode === SPACE_KEY) {
      e.preventDefault();
      e.stopPropagation();
      onChange();
    }
  };

  return (
    <div className={classNames(styles.radioButton, className)}>
      <label
        htmlFor="checkBox"
        className={classNames(styles.container, checked && styles.checked, errorMessage && styles.error)}
      >
        <span className={styles.checkmark} tabIndex={0} onKeyDown={handleCheckBoxEnter}>
          <CheckIcon />
        </span>
        <div>{label}</div>
        <input onChange={onChange} type="checkbox" id="checkBox" checked={checked} tabIndex={-1} />
      </label>
      {!!errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};
