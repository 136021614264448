import { AnnaResponseRaw, Api } from "./api";
import { InvoiceRaw, InvoiceTemplateRaw } from "../types/invoice";

export class InvoiceApiClient {
  private _api: Api;

  constructor(api: Api) {
    this._api = api;
  }

  async getInvoiceTemplate(paymentId: string): Promise<InvoiceTemplateRaw> {
    const response = await this._api.get<AnnaResponseRaw<InvoiceTemplateRaw>>(
      `/api/openbanking/i/payment-link-invoice/${paymentId}`
    );

    return response.data.data;
  }

  async sendInvoice(
    paymentId: string,
    postCode: string,
    email: string,
    name: string,
    address: string,
    description: string
  ): Promise<InvoiceTemplateRaw> {
    const response = await this._api.post<AnnaResponseRaw<InvoiceTemplateRaw>>(
      `/api/openbanking/i/payment-link-invoice/${paymentId}`,
      {
        post_code: postCode,
        email,
        name,
        address,
        description,
      }
    );

    return response.data.data;
  }

  async getInvoiceBySecureId(secureId: string): Promise<InvoiceRaw> {
    const response = await this._api.get<AnnaResponseRaw<InvoiceRaw>>(`/api/invoice-link/i/invoices/${secureId}`);

    return response.data.data;
  }
}
