import React, { ReactNode, VFC } from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "@anna-money/anna-web-ui";
import classnames from "classnames";
import styles from "./editFormSection.module.scss";
import { Button } from "../../../../components/button/button";

type EditFormSectionType = {
  children: ReactNode;
  onSave: () => void;
  onCancel: () => void;
  open: boolean;
  withMargin?: boolean;
};

export const EditFormModalSection: VFC<EditFormSectionType> = observer(
  ({ open, onSave, onCancel, children, withMargin = true }) => (
    <Modal name="editFormModalSection" open={open} showCloseButton={false} className={styles.modal}>
      <span className={styles.pageTitle}>Your payment page</span>
      <div className={classnames(styles.editFormSection, withMargin && styles.withMargin)}>
        {children}
        <div className={styles.buttonBlock}>
          <Button className={styles.buttonSave} onClick={onSave}>
            Save
          </Button>
          <Button className={styles.buttonCancel} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
);
