import { Memoize } from "typescript-memoize";
import { Api } from "./api/api";
import { AnalyticsApiClient } from "./api/analytics.api.client";
import { InvoiceApiClient } from "./api/invoice.api.client";
import { PaymentLinkFormStore } from "./sections/paymentLink/components/form/formStore";
import { PaymentApiClient } from "./api/payment.api.client";
import { PaymentLinkStore } from "./sections/paymentLink/paymentLinkStore";
import { ConfirmationStore } from "./sections/confirmation/confirmationStore";
import { CardPageStore } from "./sections/payment/card/cardPageStore";
import { BanksPageStore } from "./sections/payment/banks/banksPageStore";
import { ProvidersApiClient } from "./api/providers.api.client";
import { PaymentStore } from "./sections/payment/paymentStore";
import { InvoiceLinkStore } from "./sections/invoiceLink/invoiceLinkStore";
import { ObPaymentPageFormStore } from "./sections/obPaymentPage/obPaymentPageFormStore";
import { ObPaymentPageViewStore } from "./sections/obPaymentPage/obPaymentPageView/obPaymentPageViewStore";

export type Config = {
  publicApiUrl: string;
  stripeApiKey?: string;
  googleMapsApiKey?: string;
  authUrl: string;
  clientId: string;
  adminUrl: string;
};

export class Services {
  constructor(config: Config) {
    this._config = config;
  }

  private readonly _config: Config;

  get config(): Readonly<Config> {
    return this._config;
  }

  @Memoize()
  get apiClient(): Api {
    return new Api(this._config.publicApiUrl);
  }

  @Memoize()
  get annaAnalyticsClient(): AnalyticsApiClient {
    return new AnalyticsApiClient(this.apiClient);
  }

  @Memoize()
  get invoiceClient(): InvoiceApiClient {
    return new InvoiceApiClient(this.apiClient);
  }

  @Memoize()
  get paymentClient(): PaymentApiClient {
    return new PaymentApiClient(this.apiClient);
  }

  @Memoize()
  get providerClient(): ProvidersApiClient {
    return new ProvidersApiClient(this.apiClient);
  }

  createPaymentLinkStore(): PaymentLinkStore {
    return new PaymentLinkStore(this.paymentClient);
  }

  createPaymentLinkFormStore(): PaymentLinkFormStore {
    return new PaymentLinkFormStore(this.paymentClient);
  }

  createConfirmationStore(): ConfirmationStore {
    return new ConfirmationStore(this.invoiceClient);
  }

  createCardPageStore(): CardPageStore {
    return new CardPageStore(this.config.stripeApiKey);
  }

  createBanksPageStore(): BanksPageStore {
    return new BanksPageStore(this.providerClient);
  }

  createPaymentStore(): PaymentStore {
    return new PaymentStore(this.paymentClient);
  }

  createInvoiceLinkStore(): InvoiceLinkStore {
    return new InvoiceLinkStore(this.invoiceClient);
  }

  createObPaymentPageFormStore(): ObPaymentPageFormStore {
    return new ObPaymentPageFormStore(this.paymentClient);
  }

  createObPaymentPageViewStore(): ObPaymentPageViewStore {
    return new ObPaymentPageViewStore(this.paymentClient);
  }
}
