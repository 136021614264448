import { makeAutoObservable } from "mobx";
import { PaymentApiClient } from "../../api/payment.api.client";
import { Form } from "../../components/form/form";
import { FileData, PaymentPageCreate, PaymentPageForm, PaymentPageRaw } from "../../types/paymentPage";
import { convertResponse, formatDataToSubmit } from "../../helpers/serialization";
import { convertWorkingHoursToSubmit, formatPaymentPageToForm } from "./formatters";
import { validationConfig } from "./validation";
import { isObject } from "./helpers";

export type ObPaymentPageFormType = {
  form: Form<PaymentPageForm>;
  modalHeaderForm: Form<{ backgroundImage: string; logo: string; switchedOnLogo: boolean }>;
};

type ObPaymentPageFormState = "loading" | "error" | ObPaymentPageFormType;
export type ObPaymentPageValidationErrors = Record<string, string>;
type SaveStatusType = "idle" | "saving" | "saved" | "error";

export class ObPaymentPageFormStore {
  private _state: ObPaymentPageFormState = "loading";

  private _errors: ObPaymentPageValidationErrors = {};

  private _firstUse = false;

  private _token = null;

  private _saveStatus: SaveStatusType = "idle";

  private _api: PaymentApiClient;

  constructor(client: PaymentApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): ObPaymentPageFormState {
    return this._state;
  }

  private set state(state: ObPaymentPageFormState) {
    this._state = state;
  }

  get errors(): ObPaymentPageValidationErrors {
    return this._errors;
  }

  private set errors(errors: ObPaymentPageValidationErrors) {
    this._errors = errors;
  }

  getValidationError(field: string): string | undefined {
    return this.errors[field];
  }

  clearValidationError(field: string): void {
    if (this.errors[field]) {
      this.errors[field] = "";
    }
  }

  getFieldsWithError(): string[] {
    const serverErrors = Object.keys(this._errors).filter((e) => this._errors[e] !== "") || [];
    let formErrors = [];
    if (this.state !== "loading" && this.state !== "error") {
      const errors = this.state.form.getErrors();
      formErrors = Object.keys(errors).filter((e) => errors[e] !== "") || [];
    }
    return [...serverErrors, ...formErrors];
  }

  get firstUse(): boolean {
    return this._firstUse;
  }

  private set firstUse(value: boolean) {
    this._firstUse = value;
  }

  get token(): string {
    return this._token;
  }

  private set token(value: string) {
    this._token = value;
  }

  setToken = (token: string): void => {
    this.token = token;
  };

  get saveStatus(): SaveStatusType {
    return this._saveStatus;
  }

  private set saveStatus(value: SaveStatusType) {
    this._saveStatus = value;
  }

  private async _getLogo(url: string): Promise<string> {
    const blob = await this._api.getLogo(url);

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  private async _getFileId(fileData: FileData, file: File): Promise<Nullable<string>> {
    if (fileData) {
      return fileData.id;
    }
    if (file) {
      const data = await this._api.uploadFile(this.token, file);
      return data.id;
    }
    return null;
  }

  async load(id?: string): Promise<void> {
    if (!id) {
      this.firstUse = true;
      const form = new Form(formatPaymentPageToForm(this.firstUse), validationConfig);
      const { backgroundImage, logo: logoUrl, switchedOnLogo } = form.values;
      const modalHeaderForm = new Form({ backgroundImage, logo: logoUrl, switchedOnLogo });
      this.state = {
        form,
        modalHeaderForm,
      };
      return;
    }
    try {
      this.state = "loading";
      this.firstUse = false;
      const data = await this._api.getPaymentPage(id);
      const form = new Form(formatPaymentPageToForm(this.firstUse, data), validationConfig);
      const { backgroundImageFileData, logoFileData, switchedOnLogo } = form.values;
      const modalHeaderForm = new Form({ backgroundImage: null, logo: null, switchedOnLogo });

      if (backgroundImageFileData) {
        const backgroundImage = await this._getLogo(backgroundImageFileData.url);
        form.onChange("backgroundImage", backgroundImage);
        modalHeaderForm.onChange("backgroundImage", backgroundImage);
      }

      if (logoFileData) {
        const logo = await this._getLogo(logoFileData.url);
        form.onChange("logo", logo);
        modalHeaderForm.onChange("logo", logo);
      }

      this.state = {
        form,
        modalHeaderForm,
      };
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async runCancelScenario(): Promise<void> {
    await this._api.cancelEditingPaymentPage({ token: this.token });
  }

  async runShareScenario(): Promise<void> {
    await this._api.sharePaymentPage({ token: this.token });
  }

  async runPreviewScenario(): Promise<void> {
    await this._api.previewPaymentPage({ token: this.token });
  }

  async runSavedScenario(): Promise<void> {
    await this._api.sendFollowUpSkill({ token: this.token });
  }

  saveData = async (formData: PaymentPageForm): Promise<PaymentPageRaw> => {
    const { publicId, ...data } = formData;
    const {
      workingHours,
      contactEmail,
      contactPhone,
      contactWebsite,
      linkedinLink,
      amount,
      paymentLinkDescription,
      logoFile,
      logoFileData,
      backgroundImageFile,
      backgroundImageFileData,
      currency,
      address,
      paymentPageDescription,
      googleMapsPlaceId,
      businessType,
      facebookLink,
      instagramLink,
    } = data;
    try {
      this.saveStatus = "saving";

      const backgroundImageFileId = await this._getFileId(backgroundImageFileData, backgroundImageFile);
      const logoFileId = await this._getFileId(logoFileData, logoFile);
      const formattedData = formatDataToSubmit<PaymentPageCreate>({
        workingHours: convertWorkingHoursToSubmit(workingHours),
        contactEmail: contactEmail || null,
        contactPhone,
        contactWebsite: contactWebsite || null,
        linkedinLink: linkedinLink || null,
        amount: amount || null,
        paymentLinkDescription,
        logoFileId,
        backgroundImageFileId,
        currency,
        address,
        paymentPageDescription,
        googleMapsPlaceId,
        businessType,
        facebookLink: facebookLink || null,
        instagramLink: instagramLink || null,
      });

      let result;
      if (publicId) {
        result = await this._api.updatePaymentPage(publicId, { ...formattedData, token: this.token });
      } else {
        result = await this._api.createPaymentPage({ ...formattedData, token: this.token });
      }

      if (result) {
        this.saveStatus = "saved";
        return result;
      }
      return null;
    } catch (e) {
      if (e?.status === 413) {
        const regExp = /\d+/;
        const maxSize = e.data?.match(regExp)?.[0];
        const maxMbSize = maxSize && (Number(maxSize) / 1024 / 1024).toFixed();
        this.errors = {
          ...this.errors,
          imageSize: !maxMbSize
            ? "The image size is too large."
            : `The image size is too large. Maximum size is ${maxMbSize}Mb`,
        };
        this.saveStatus = "idle";
        return null;
      }

      if (e?.status === 403 && e?.data.error?.message === "Invalid token") {
        this.errors = {
          ...this.errors,
          token: "Authentication error",
        };
        this.saveStatus = "idle";
        return null;
      }

      if (e?.status === 400 && isObject(e?.data.error?.validation_errors)) {
        const { validation_errors: validationErrors } = e.data.error;
        if (isObject(validationErrors)) {
          const errors: Record<string, string> = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(convertResponse(validationErrors))) {
            if (Array.isArray(value)) {
              errors[key] = value.join("");
            }
          }
          this.errors = {
            ...this.errors,
            ...errors,
          };

          this.saveStatus = "idle";
          return null;
        }
      }

      this.saveStatus = "error";
      throw e;
    }
  };
}
