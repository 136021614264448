import React, { FC, useContext } from "react";
import { Services } from "../services";
import { useFactory } from "../helpers/reactHooks";

type AppServicesProps = { children: React.ReactNode; services: Services };

const AppServicesContext = React.createContext<Services | null>(null);

export const AppServices: FC<AppServicesProps> = ({ children, services }) => (
  <AppServicesContext.Provider value={services}>{children}</AppServicesContext.Provider>
);

export function useServices(): Services {
  const context = useContext(AppServicesContext);
  if (!context) {
    throw new Error("Services must be requested in AppServices context");
  }
  return context;
}

export function useServiceFactory<T>(factory: (services: Services) => T): T {
  const services = useServices();
  return useFactory(() => factory(services));
}
