import classNames from "classnames";
import React, { FC } from "react";
import styles from "./Label.module.scss";

type LabelProps = {
  forId?: string;
  labelText: string;
  className?: string;
  error?: boolean;
  isActive?: boolean;
};

export const Label: FC<LabelProps> = ({ forId, error = false, labelText, className, isActive = false }) => (
  <label
    htmlFor={forId}
    className={classNames(
      styles.label,
      {
        [styles.active]: isActive,
        [styles.error]: error,
      },
      className
    )}
  >
    {labelText}
  </label>
);
