import React, { VFC } from "react";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import styles from "./Toggle.module.scss";
import { useFactory } from "../../helpers/reactHooks";

type ToggleProps = {
  value: boolean;
  onChange?: (e: boolean) => void;
  className?: string;
};

export const Toggle: VFC<ToggleProps> = ({ value, onChange, className }: ToggleProps) => {
  const id = useFactory(() => uuid());

  return (
    <span className={classNames(styles.toggleContainer, className)}>
      <label htmlFor={id} className={styles.toggleSwitch}>
        <input id={id} type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
        <span className={styles.switch} />
      </label>
    </span>
  );
};
