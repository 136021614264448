import React, { useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { Button, IconButton } from "@anna-money/anna-web-ui";
import { Autocomplete } from "@react-google-maps/api";
import classNames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import styles from "./businessLocationSection.module.scss";
import { Toggle } from "../../../../components/toggle/toggle";
import { MapComponent } from "./mapComponent/mapComponent";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { useFactory } from "../../../../helpers/reactHooks";
import { Form } from "../../../../components/form/form";
import { Input } from "../../../../components/input/input";

export const BusinessLocationSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const { switchedOnBusinessLocation, address, googleMapsPlaceId } = form.values;
  const modalForm = useFactory(() => new Form({ switchedOnBusinessLocation, address, googleMapsPlaceId }));
  const [editMode, setEditMode] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);

  const [input, setInput] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);

  const onPlaceChanged = (formData) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      formData.onChange("googleMapsPlaceId", place?.place_id);
      formData.onChange("address", place?.formatted_address);
      setInput(autocomplete.getPlace().formatted_address);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const renderViewContent = () => (
    <div className={styles.viewSection}>
      <div className={styles.businessLocationSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionViewTitle}>
            <span className={styles.sectionSubtitle}>Business location</span>
          </div>
          <IconButton icon="actionEdit" iconColor="themeGreen" size="s" onClick={() => setEditMode(true)} />
        </div>

        <span className={styles.address}>{address}</span>
        <MapComponent placeId={form.getValue("googleMapsPlaceId")} />
      </div>
    </div>
  );

  const handleChangeToggle = (value, formData) => {
    formData.onChange("switchedOnBusinessLocation", value);
    if (!value) {
      formData.onMultipleChange([
        { field: "googleMapsPlaceId", value: null },
        { field: "address", value: null },
      ]);
    }
  };

  const renderFormContent = (formData, isModal) => (
    <div className={classNames(styles.section, styles.withSeparator)}>
      <div className={styles.businessLocationSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            <span className={styles.sectionSubtitle}>Business location</span>
            <span className={styles.sectionDescription}>
              Include a map of your business location{" "}
              {formData.getValue("address") && "We’ve got your trading address as:"}
            </span>
          </div>
          <Toggle
            value={formData.getValue("switchedOnBusinessLocation")}
            onChange={(value) => handleChangeToggle(value, formData)}
          />
        </div>

        {formData.getValue("switchedOnBusinessLocation") && (
          <>
            {changeAddress ? (
              <Autocomplete
                className={styles.autocomplete}
                onLoad={setAutocomplete}
                onPlaceChanged={() => onPlaceChanged(formData)}
              >
                <Input
                  className={styles.field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
                  value={input}
                />
              </Autocomplete>
            ) : (
              <>
                <span className={classNames(styles.address, isModal && styles.textBold)}>
                  {formData.getValue("address")}
                </span>
                <Button
                  text="Change address"
                  className={styles.changeButton}
                  type="text"
                  onClick={() => setChangeAddress(true)}
                />
              </>
            )}
            <MapComponent placeId={formData.getValue("googleMapsPlaceId")} />
          </>
        )}
      </div>
    </div>
  );

  const handleCancelEditModal = () => {
    setEditMode(false);
    modalForm.onMultipleChange([
      { field: "address", value: form.getValue("address") },
      { field: "googleMapsPlaceId", value: form.getValue("googleMapsPlaceId") },
      { field: "switchedOnBusinessLocation", value: form.getValue("switchedOnBusinessLocation") },
    ]);
  };

  const handleSaveEditModal = () => {
    setEditMode(false);
    form.onMultipleChange([
      { field: "address", value: modalForm.getValue("address") },
      { field: "googleMapsPlaceId", value: modalForm.getValue("googleMapsPlaceId") },
      { field: "switchedOnBusinessLocation", value: modalForm.getValue("switchedOnBusinessLocation") },
    ]);
  };

  if (store.firstUse || !form.getValue("address")) {
    return renderFormContent(form, false);
  }

  return (
    <>
      {renderViewContent()}
      <EditFormModalSection open={editMode} onCancel={handleCancelEditModal} onSave={handleSaveEditModal}>
        {renderFormContent(modalForm, true)}
      </EditFormModalSection>
    </>
  );
});
