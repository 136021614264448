import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { App } from "./app/app";
import "./index.css";

axios.get("/config.json").then((response) => {
  const config = response.data;

  if (config.googleAnalyticsId) {
    window.ga("create", config.googleAnalyticsId, "auto");
    window.ga("send", "pageview");
  }

  ReactDOM.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
    document.getElementById("root")
  );
});
