import React, {VFC} from "react";
import classNames from "classnames";
import {useAnnaAppContext} from "@anna-money/anna-web-ui";
import appStoreImage from "./resources/app-store.png";
import googlePlayImage from "./resources/google-play.png";
import cardImage from "./resources/Image-Hero.png";
import cardImage2 from "./resources/Image-Hero@2x.png";
import cardImage3 from "./resources/Image-Hero@3x.png";
import styles from "./SideContent.module.scss";

type SideContentProps = {
  className?: string;
  buttonText?: string;
  rowView?: boolean;
  withLogo?: boolean;
  utmSource?: string,
  utmCampaign?: string,
};


export const SideContent: VFC<SideContentProps> = ({
                                                     className,
                                                     buttonText,
                                                     rowView,
                                                     withLogo,
                                                     utmSource,
                                                     utmCampaign
                                                   }: SideContentProps) => {
  const isMobile = useAnnaAppContext().displayType === "mobile";
  const redirectUrl = `https://go.anna.money/accept-payments/?utm_source=${utmSource}&utm_campaign=${utmCampaign}`;

  return (
    <div className={classNames(styles.sideContent, className)}>
      <div className={classNames(styles.sideContentCenter, rowView && styles.rowView)}>
        {withLogo && isMobile && <div className={styles.logo}/>}
        <div className={styles.sideContentImage}>
          <a className={styles.sideContentButtonAppStore} href="https://apps.apple.com/app/id1333008275">
            <img src={appStoreImage} alt="Link to App Store"/>
          </a>
          <a
            className={styles.sideContentButtonGooglePlay}
            href="https://play.google.com/store/apps/details?id=com.anna.money.app"
          >
            <img src={googlePlayImage} alt="Link to Google Play"/>
          </a>
          <img
            alt=""
            className={styles.sideContentImageCard}
            src={cardImage}
            srcSet={`${cardImage2} 2x, ${cardImage3} 3x`}
          />
        </div>
        <div className={styles.sideContentText}>
          {withLogo && !isMobile && <div className={styles.logo}/>}
          <div className={styles.sideTitle}>Meet ANNA, the business account and tax app for your small business</div>
          <div className={styles.sideDescription}>
            ANNA Money is the one-stop shop for all your business admin. Not only will you get a business account and
            debit card, we&rsquo;ll also sort your invoicing, expenses and company taxes. Sweet.
          </div>
          <a className={styles.sideButton}
             href={redirectUrl}>
            {buttonText || "Learn more"}
          </a>
        </div>
      </div>
    </div>
  );
};
