import React, { VFC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, Text, useAnnaAppContext } from "@anna-money/anna-web-ui";
import copy from "copy-to-clipboard";
import { message } from "antd";
import classNames from "classnames";
import styles from "./headerViewSection.module.scss";
import { LogoIcon } from "../../../../icons/icons";
import { FormSectionType } from "../obPaymentPageView";

type PaymentInfoType = {
  beneficiaryName: string;
  beneficiarySortCode: string;
  beneficiaryAccountNumber: string;
};

export const PaymentInfo: VFC<PaymentInfoType> = ({
  beneficiaryName,
  beneficiarySortCode,
  beneficiaryAccountNumber,
}: PaymentInfoType) => {
  const handleCopy = () => {
    copy(`${beneficiaryName}\nSort code: ${beneficiarySortCode}\nAccount: ${beneficiaryAccountNumber}`);
    message.success("Bank details has been copied to clipboard");
  };
  return (
    <div className={styles.paymentInfo}>
      <LogoIcon />
      <div className={styles.content}>
        <div className={styles.contentText}>
          <span className={styles.subtitle}>{beneficiaryName}</span>
          <span className={styles.text}>Sort code: {beneficiarySortCode}</span>
          <Text className={styles.text} tag="div">
            Account#: {beneficiaryAccountNumber}
          </Text>
        </div>
        <IconButton icon="actionDuplicate" iconColor="themeGreen" onClick={handleCopy} />
      </div>
    </div>
  );
};

export const HeaderViewSection: VFC<FormSectionType> = observer(({ state }) => {
  const isMobile = useAnnaAppContext().displayType === "mobile";
  const { backgroundImage, logo, tradingName, beneficiaryName, beneficiarySortCode, businessType, beneficiaryAccountNumber } = state;
  const isFullHeaderView = logo && backgroundImage;
  const isEmptyHeaderView = !logo && !backgroundImage;

  return (
    <>
      <div className={styles.header}>
        {backgroundImage && <img className={styles.backgroundImage} src={backgroundImage} alt="business" />}
        {!!logo && (
          <div className={classNames(styles.logoBlock, !backgroundImage && styles.block)}>
            <>
              <img className={styles.headerLogo} src={logo} alt="logo" />
            </>
          </div>
        )}
      </div>

      <div className={classNames(styles.titleBlock, (isFullHeaderView || isEmptyHeaderView) && styles.withMargin)}>
        <div className={styles.title}>
          <span className={styles.name}>{tradingName || beneficiaryName}</span>
          <div className={styles.businessType}>{businessType}</div>
        </div>
        {!isMobile && (
          <PaymentInfo
            beneficiaryName={beneficiaryName}
            beneficiarySortCode={beneficiarySortCode}
            beneficiaryAccountNumber={beneficiaryAccountNumber}
          />
        )}
      </div>
    </>
  );
});
