import React, { VFC } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useMount } from "../../helpers/reactHooks";
import { Status } from "../../components/status/status";
import { ErrorState } from "../../helpers/error";
import { Form } from "./components/form/form";
import { useServiceFactory } from "../../app/appServices";

export const PaymentLink: VFC = observer(() => {
  const store = useServiceFactory((x) => x.createPaymentLinkStore());
  const params = useParams();
  const { paymentTemplateId, productId } = params;

  useMount(() => store.load(paymentTemplateId, productId));

  if (store.state === "loading") {
    return <Status type="progress" />;
  }

  if (store.state instanceof ErrorState) {
    return <Status type="error" title="We ran into some problems..." text={store.state.message} />;
  }

  return <Form template={store.state} />;
});
