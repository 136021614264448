import { useParams } from "react-router";
import React, { useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import styles from "./Confirmation.module.scss";
import annaLogo from "../../resources/annaLogo.svg";
import luckyCat from "../../resources/luckyCat.svg";
import { Status } from "../../components/status/status";
import { RadioButton } from "../../components/radioButton/radioButton";
import { useMount } from "../../helpers/reactHooks";
import { InvoiceDataState, InvoiceTemplate } from "./confirmationStore";
import radioButtonStyles from "../../components/radioButton/RadioButton.module.scss";
import { ErrorState } from "../../helpers/error";
import { useServiceFactory } from "../../app/appServices";
import { Button } from "../../components/button/button";
import { ReactComponent as TickCircle } from "./resources/tickCircle.svg";

export const Confirmation: VFC = observer(() => {
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("payment_template_id");
  const [checked, toggleCheckbox] = useState(false);
  const store = useServiceFactory((x) => x.createConfirmationStore());
  const params = useParams();

  useMount(() => store.load(params.paymentId));

  const handleCancel = () => {
    document.location.href = "https://go.anna.money/accept-payments/";
  };

  const canSend = (state: InvoiceDataState) => {
    const { emailValid, descriptionValid, nameValid } = state;

    return checked && emailValid && descriptionValid && nameValid;
  };

  const handleSubmit = async (data: InvoiceTemplate) => {
    const { invoice, dataState } = store.canSend(data);

    if (!canSend(dataState)) {
      return;
    }

    await store.submit(invoice);
  };

  const renderContent = () => {
    if (store.state === "loading") {
      return (
        <Status
          type="progress"
          text="We need a couple of moments to check the status of the payment, please do not close this page
          if you need a confirmation."
        />
      );
    }

    if (store.state instanceof ErrorState || params.state === "failed") {
      return (
        <Status type="error" backUrl={templateId ? `/p/${templateId}` : undefined}>
          <div>We ran into some problems</div>
          <div>with your payment.</div>
        </Status>
      );
    }

    const { invoice } = store.state;
    const { dataState } = store;

    if (store.state.status === "success") {
      return (
        <Status
          type="success"
          headerContent={
            <>
              <span className={styles.statusHeader}>
                <TickCircle />
              </span>
              <span className={styles.statusTitle}>You just paid</span>
            </>
          }
        >
          <div className={styles.statusBlock}>
            <div className={styles.contentBlock}>
              <div className={styles.companyName}>{store.state.invoice.beneficiaryName}</div>
              <div className={styles.description}>
                The money will be in their ANNA account shortly, and we&apos;ll email you a payment confirmation for
                your records
              </div>
              <div className={styles.thankYou}>Thank you for your business</div>
            </div>
            <div className={styles.buttonBlock}>
              <div
                className={styles.description}
              >{`Why not join ${store.state.invoice.beneficiaryName} and get an ANNA account for your business`}</div>
              <Button className={styles.button} href="https://anna.money">
                Open an Anna account
              </Button>
            </div>
          </div>
        </Status>
      );
    }

    const logo = invoice.logoUrl || luckyCat;

    return (
      <div className={styles.confirmation}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={annaLogo} alt="logo" />
          </div>
          <div className={styles.illustration}>
            <img src={logo} alt="cat" />
          </div>
          <div className={styles.title}>
            Your payment for £{invoice.amount} to {invoice.beneficiaryName} with reference {invoice.beneficiaryReference} is submitted
          </div>
          <div className={styles.description}>
            Please fill in the details for {invoice.beneficiaryName} to issue an invoice and send it to you via email
          </div>
          <div className={styles.form}>
            <div className={classNames(styles.field, dataState.emailReadonly && styles.fieldReadonly)}>
              <input
                inputMode="email"
                readOnly={dataState.emailReadonly}
                placeholder="E-mail"
                value={invoice.email}
                onChange={(e) => store.onEmailChange(e.target.value, invoice)}
              />
            </div>
            <div className={styles.fieldError}>{!dataState.emailValid && "* Should be a valid email address"}</div>
            <div className={classNames(styles.field, dataState.descriptionReadonly && styles.fieldReadonly)}>
              <input
                placeholder="Description"
                readOnly={dataState.descriptionReadonly}
                value={invoice.description}
                onChange={(e) => store.onDescriptionChange(e.target.value, invoice)}
              />
            </div>
            <div className={styles.fieldError}>{!dataState.descriptionValid && "* Can’t be empty"}</div>
            <div className={classNames(styles.field, dataState.nameReadonly && styles.fieldReadonly)}>
              <input
                placeholder="Your name"
                value={invoice.name}
                readOnly={dataState.nameReadonly}
                onChange={(e) => store.onNameChange(e.target.value, invoice)}
              />
            </div>
            <div className={styles.fieldError}>{!dataState.nameValid && "* Can’t be empty"}</div>
            <div className={styles.field}>
              <input
                placeholder="Post code (optional)"
                value={invoice.postCode}
                onChange={(e) => store.onPostCodeChange(e.target.value, invoice)}
              />
            </div>
            <div className={styles.fieldError} />
            <div className={styles.field}>
              <input
                placeholder="Your address (optional)"
                value={invoice.address}
                onChange={(e) => store.onAddressChange(e.target.value, invoice)}
              />
            </div>
            <div className={styles.fieldError} />
          </div>
          <div>
            <RadioButton
              className={radioButtonStyles.agreeRadioButton}
              onChange={() => toggleCheckbox(!checked)}
              label={
                <>
                  I agree to{" "}
                  <a href="https://anna.money/privacy-policy" target="_blank" rel="noreferrer">
                    ANNA’s privacy policy
                  </a>
                </>
              }
              checked={checked}
            />
          </div>
          <div
            className={classNames(styles.primaryButton, !canSend(dataState) && styles.primaryButtonDisabled)}
            onClick={() => handleSubmit(invoice)}
          >
            Submit
          </div>
          <div className={styles.cancelButton} onClick={handleCancel}>
            I don’t need a confirmation
          </div>
        </div>
      </div>
    );
  };

  return <>{renderContent()}</>;
});
