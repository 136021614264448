const nameRegex = /(.)?([A-Z])/g;

export const convertPropertyName = (name: string): string =>
  name.replace(nameRegex, (_, prevChar: string, capitalChar: string) =>
    prevChar ? `${prevChar}_${capitalChar.toLowerCase()}` : capitalChar.toLowerCase()
  );

export function formatDataToSubmit<T>(data: Record<string, unknown>): T {
  const result = {} as T;
  // eslint-disable-next-line no-return-assign
  Object.keys(data).forEach((key) => (result[convertPropertyName(key)] = data[key]));
  return result;
}

export const convertToCamelCase = (name: string): string => name.replace(/_./g, (char) => char[1].toUpperCase());

export function convertResponse<T>(data: Record<string, unknown>): T {
  const result = {} as T;
  // eslint-disable-next-line no-return-assign
  Object.keys(data).forEach((key) => (result[convertToCamelCase(key)] = data[key]));
  return result;
}
