import { currencySymbol } from "./constants";

export const formatMoney = (amount: number, currency = "GBP"): string | null => {
  if (amount) {
    const result = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(Math.abs(amount));
    const symbol = currencySymbol[currency];
    const isUndefinedCurrency = symbol === undefined;
    let digit = "";
    if (amount < 0) {
      digit = "-";
    }
    if (isUndefinedCurrency) {
      return `${digit}${result} ${currency}`;
    }

    return `${digit}${symbol}${result}`;
  }
  return null;
};
