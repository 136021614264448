import React, { useEffect, useMemo, VFC } from "react";
import { observer } from "mobx-react-lite";
import { AnnaApp, Loader, useAnnaAppContext, WindowManager } from "@anna-money/anna-web-ui";
import { useParams } from "react-router";
import classNames from "classnames";
import styles from "./obPaymentPageView.module.scss";
import { SideContent } from "../../../components/sideContent/sideContent";
import { useServiceFactory } from "../../../app/appServices";
import { HeaderViewSection } from "./headerViewSection/headerViewSection";
import { ContactsDetailsSection } from "./contactsDetailsSection/contactsDetailsSection";
import { PaySection } from "./paySection/paySection";
import { PaymentPageForm } from "../../../types/paymentPage";
import { ObPaymentPageViewStore } from "./obPaymentPageViewStore";
import { Status } from "../../../components/status/status";

export type FormSectionType = {
  state: PaymentPageForm;
  store: ObPaymentPageViewStore;
};

export const ObPaymentPageView: VFC = observer(() => {
  const store = useServiceFactory((x) => x.createObPaymentPageViewStore());
  const params = useParams();
  const isMobile = useAnnaAppContext().displayType === "mobile";

  useEffect(() => {
    if (store.redirectUrl) {
      window.location.replace(store.redirectUrl);
    }
  }, [store.redirectUrl]);

  useEffect(() => {
    void store.load(params.id);
  }, []);

  const hasContactSection = useMemo(() => {
    if (store.state !== "error" && store.state !== "loading") {
      const {
        googleMapsPlaceId,
        address,
        contactEmail,
        contactPhone,
        contactWebsite,
        linkedinLink,
        facebookLink,
        instagramLink,
        workingHours,
      } = store.state.pageData;
      return (
        [
          googleMapsPlaceId,
          address,
          contactEmail,
          contactPhone,
          contactWebsite,
          linkedinLink,
          facebookLink,
          instagramLink,
          workingHours?.length,
        ].filter(Boolean).length > 0
      );
    }
    return true;
  }, [store.state]);

  if (store.state === "error") {
    return <Status type="error" title="Something goes wrong..." canReload />;
  }

  if (store.state === "loading") {
    return <Loader />;
  }

  return (
    <AnnaApp>
      <WindowManager>
        <div className={styles.wrapper}>
          <main className={classNames(styles.mainSection, !hasContactSection && styles.fullView)}>
            <HeaderViewSection store={store} state={store.state.pageData} />
            <PaySection store={store} form={store.state.form} state={store.state.pageData} />
          </main>
          {hasContactSection && (
            <aside className={styles.aside}>
              <ContactsDetailsSection store={store} state={store.state.pageData} />
            </aside>
          )}
        </div>
        <div className={styles.sideWrapper}>
          <SideContent
            withLogo
            rowView
            buttonText="Get the free app"
            className={classNames(styles.sideContent, isMobile && styles.mobile, !hasContactSection && styles.fullView)}
            utmSource={store.state.pageData.publicId}
            utmCampaign="payment_page_banner"
          />
        </div>
      </WindowManager>
    </AnnaApp>
  );
});
