import { makeAutoObservable } from "mobx";
import { ErrorState } from "../../../helpers/error";
import { Provider } from "../../../types/banks";
import { ProvidersApiClient } from "../../../api/providers.api.client";

type BankPageStateData = {
  config: { token: string; paymentId: string };
  list: Provider[];
};
type BanksPageState = "loading" | ErrorState | BankPageStateData;

export class BanksPageStore {
  private _state: BanksPageState = "loading";

  private _fullList: Provider[] = [];

  private _redirectUrl: Nullable<string> = null;

  private _api: ProvidersApiClient;

  constructor(client: ProvidersApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): BanksPageState {
    return this._state;
  }

  private set state(state: BanksPageState) {
    this._state = state;
  }

  get redirectUrl(): Nullable<string> {
    return this._redirectUrl;
  }

  private set redirectUrl(url: Nullable<string>) {
    this._redirectUrl = url;
  }

  async load(paymentId: Nullable<string>, token: Nullable<string>): Promise<void> {
    if (!paymentId || !token) {
      this.state = new ErrorState("PaymentId or session token is not defined");
      return;
    }
    this.state = "loading";
    try {
      const { items } = await this._api.getProviders(paymentId);
      this.state = {
        list: items,
        config: {
          paymentId,
          token,
        },
      };
      this._fullList = items;
    } catch (e) {
      this.state = new ErrorState("Error loading the banks list");
      throw e;
    }
  }

  async selectProvider(paymentId: string, token: string, providerId: string): Promise<void> {
    try {
      const { auth_url: authUrl } = await this._api.selectProvider(paymentId, token, providerId);
      this.redirectUrl = authUrl;
    } catch (e) {
      this.state = new ErrorState("There is an issue with the provider form load. Please try again later.");
      throw e;
    }
  }

  doSearch(query: string): void {
    if (this.state === "loading" || this.state instanceof ErrorState) {
      return;
    }
    if (!query) {
      this.state = {
        config: this.state.config,
        list: this._fullList,
      };
    } else {
      this.state = {
        config: this.state.config,
        list: this._fullList.filter((bank) => bank.displayable_name.toLowerCase().indexOf(query) > -1),
      };
    }
  }
}
