export type DropdownOption = {
  value: number | string | undefined;
  label: string;
};

export const generateTimeOptions = (): DropdownOption[] => {
  let hours;
  let minutes;
  let ampm;
  const options = [];
  for (let i = 30; i <= 1440; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    ampm = hours % 24 < 12 ? "AM" : "PM";

    if (hours === 0) {
      hours = 12;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    options.push({ value: `${hours}:${minutes}`, label: `${hours}:${minutes} ${ampm}` });
  }
  options.push({ value: "Closed", label: "Closed" });
  return options;
};

export function isObject(value: unknown): value is Record<string, unknown> {
  return !!value && typeof value === "object";
}
