import React, { useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import styles from "./openingHoursSection.module.scss";
import { Toggle } from "../../../../components/toggle/toggle";
import { useFactory } from "../../../../helpers/reactHooks";
import { Form } from "../../../../components/form/form";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { generateTimeOptions } from "../../helpers";
import { WeekdayWorkingHoursData } from "../../../../types/paymentPage";
import { Dropdown } from "../../../../components/dropdown/dropdown";

const days = ["Mon", "Tue", "Weds", "Thurs", "Fri", "Sat", "Sun"];
export const daysLong = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const getDayInfo = (dayData: WeekdayWorkingHoursData): string => {
  if (
    !dayData?.open_time ||
    !dayData?.close_time ||
    dayData?.close_time === "Closed" ||
    dayData?.open_time === "Closed"
  ) {
    return "Closed";
  }
  return `${dayData?.open_time} - ${dayData?.close_time}`;
};

export const OpeningHoursSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const { workingHours, switchedOnOpeningHours } = form.values;

  const modalForm = useFactory(() => new Form({ workingHours, switchedOnOpeningHours }));
  const [isFilled, setFilled] = useState(!!workingHours.length);
  const [editMode, setEditMode] = useState(false);

  const handleToggle = (value: boolean, formData): void => {
    formData.onChange("switchedOnOpeningHours", value);
    if (!value) {
      formData.onChange("workingHours", []);
    }
  };

  const options = generateTimeOptions();

  const renderViewContent = () => (
    <div className={styles.viewSection}>
      <div className={styles.openingHoursSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionViewTitle}>
            <span className={styles.sectionSubtitle}>Opening hours</span>
          </div>

          <IconButton icon="actionEdit" iconColor="themeGreen" size="s" onClick={() => setEditMode(true)} />
        </div>

        <div className={styles.sectionViewContent}>
          {daysLong.map((day, i) => {
            const key = `days-info-${i}`;
            const dayData = workingHours?.find((el) => el.weekday === i + 1);
            return (
              <div className={styles.rowContent} key={key}>
                <span className={styles.text}>{day}</span>

                <span className={styles.hours}>{getDayInfo(dayData)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const renderFormContent = (formData, isModal) => (
    <div className={classNames(styles.section, styles.withSeparator)}>
      <div className={styles.openingHoursSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            <span className={styles.sectionSubtitle}>Opening hours</span>
            <span className={styles.sectionDescription}>
              Include your openings hours to keep your customers informed of your hours of business
            </span>
          </div>
          <Toggle
            value={formData.getValue("switchedOnOpeningHours")}
            onChange={(value) => handleToggle(value, formData)}
          />
        </div>

        {formData.getValue("switchedOnOpeningHours") && (
          <>
            {days.map((day, i) => {
              const openingHours = formData.values.workingHours;
              const key = `open-time-${i}`;
              const dayData = openingHours?.find((el) => el.weekday === i + 1);
              return (
                <div className={styles.rowContent} key={key}>
                  <span className={classNames(styles.text, isModal && styles.modalText)}>{day}</span>
                  <div className={styles.dropdownGroup}>
                    <Dropdown
                      className={styles.field}
                      label={i === 0 && "From"}
                      value={dayData?.open_time}
                      options={options}
                      onChange={(v) => {
                        const ind = openingHours?.findIndex((el) => el.weekday === i + 1);
                        if (ind !== -1) {
                          formData.onChange(`workingHours[${ind}].open_time`, v);
                        } else {
                          formData.onChange(`workingHours`, [
                            ...openingHours,
                            { open_time: v, close_time: null, weekday: i + 1 },
                          ]);
                        }
                      }}
                    />

                    <Dropdown
                      className={styles.field}
                      label={i === 0 && "To"}
                      options={options}
                      value={dayData?.close_time}
                      onChange={(v) => {
                        const ind = openingHours?.findIndex((el) => el.weekday === i + 1);
                        if (ind !== -1) {
                          formData.onChange(`workingHours[${ind}].close_time`, v);
                        } else {
                          formData.onChange(`workingHours`, [
                            ...openingHours,
                            { open_time: null, close_time: v, weekday: i + 1 },
                          ]);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );

  const handleCancelEditModal = () => {
    setEditMode(false);
    modalForm.onChange("workingHours", form.getValue("workingHours"));
  };

  const handleSaveEditModal = () => {
    setEditMode(false);
    if (!modalForm.getValue("workingHours")?.length) {
      form.onChange("switchedOnOpeningHours", false);
      setFilled(false);
    } else {
      setFilled(true);
    }
    form.onChange("workingHours", modalForm.getValue("workingHours"));
  };

  if (store.firstUse || !isFilled) {
    return renderFormContent(form, false);
  }

  return (
    <>
      {renderViewContent()}
      <EditFormModalSection open={editMode} onCancel={handleCancelEditModal} onSave={handleSaveEditModal}>
        <div>{renderFormContent(modalForm, true)}</div>
      </EditFormModalSection>
    </>
  );
});
