import React, { FC, ReactNode } from "react";
import annaLogo from "../../resources/annaLogo.svg";
import animatedCat from "./resources/cat_anim.gif";
import waitingLizard from "./resources/59_waiting_lizard_RGB.svg";
import happyBird from "./resources/happy_bird_RGB.svg";
import { SideContent } from "../sideContent/sideContent";
import styles from "./Status.module.scss";

type StatusType = "progress" | "error" | "success";
type StatusProps = {
  type: StatusType;
  title?: string;
  text?: string;
  children?: ReactNode;
  backUrl?: string;
  canReload?: boolean;
  headerContent?: ReactNode;
};

const illustrationMap = new Map<StatusType, string>([
  ["progress", animatedCat],
  ["error", waitingLizard],
  ["success", happyBird],
]);

export const Status: FC<StatusProps> = ({ type, text, title, children, backUrl, canReload, headerContent }) => {
  const tryAgain = () => {
    if (backUrl) {
      window.location.href = backUrl;
      return;
    }

    if (canReload) {
      window.location.reload();
    }
  };

  return (
    <div className={styles.status}>
      <div className={styles.centerBlock}>
        {headerContent || (
          <>
            <div className={styles.logo}>
              <img src={annaLogo} alt="logo" />
            </div>
            <div className={styles.illustration}>
              <img src={illustrationMap.get(type)} alt="illustration" />
            </div>
          </>
        )}
        {title && <div className={styles.title}>{title}</div>}
        {text && <div className={styles.text}>{text}</div>}
        {children}

        {type === "error" && (backUrl || canReload) && (
          <>
            <div className={styles.helperText}>Do you want to try again?</div>
            <button type="button" onClick={() => tryAgain()} className={styles.button}>
              Try again
            </button>
          </>
        )}
      </div>
      {type !== "progress" && <SideContent />}
    </div>
  );
};
