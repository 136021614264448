import React, { VFC } from "react";
import { observer } from "mobx-react-lite";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Loader } from "@anna-money/anna-web-ui";
import styles from "./mapComponent.module.scss";
import { ErrorView } from "../../../../../components/errorView/errorView";
import { useServices } from "../../../../../app/appServices";

const londonPlaceId = "ChIJdd4hrwug2EcRmSrV3Vo6llI";

type MapComponentType = {
  placeId: string;
};

export const MapComponent: VFC<MapComponentType> = observer(({ placeId }) => {
  const { config } = useServices();
  const render = (status: Status) => {
    if (status === Status.FAILURE) return <ErrorView />;
    return <Loader />;
  };

  return (
    <Wrapper libraries={["places", "geometry"]} apiKey={config.googleMapsApiKey} language="en" render={render}>
      <iframe
        className={styles.mapContainer}
        width="450"
        height="250"
        frameBorder="0"
        title="some title"
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${config.googleMapsApiKey}&q=place_id:${
          placeId || londonPlaceId
        }`}
        allowFullScreen
      />
    </Wrapper>
  );
});
