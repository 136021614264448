import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Policy } from "../../../components/policy/policy";
import { useMount } from "../../../helpers/reactHooks";
import { Status } from "../../../components/status/status";
import { ErrorState } from "../../../helpers/error";
import styles from "./BanksPage.module.scss";
import iconSearch from "./resources/icon_search.svg";
import iconClear from "./resources/icon_clear.svg";
import { Provider } from "../../../types/banks";
import { Blocked } from "../../../components/blocked/blocked";
import { useServiceFactory } from "../../../app/appServices";
import { PaymentInfo } from "../../../types/payment";
import { Loader } from "../../../components/loader/loader";

type BanksPageProps = {
  details: PaymentInfo;
};

export const BanksPage: FC<BanksPageProps> = observer(({ details }) => {
  const store = useServiceFactory((x) => x.createBanksPageStore());
  const [query, setQuery] = useState<string>("");
  const [bankToShow, selectBank] = useState<Provider | null>(null);

  useMount(() => store.load(details.openbankingPaymentRequest.id, details.openbankingPaymentRequest.token));

  useEffect(() => {
    store.doSearch(query);
  }, [query, store]);

  useEffect(() => {
    if (store.redirectUrl) {
      window.location.href = store.redirectUrl;
    }
  }, [store.redirectUrl]);

  const handleConfirm = async (paymentId: string, token: string, bankId: string) => {
    await store.selectProvider(paymentId, token, bankId);
  };

  const render = () => {
    if (store.state === "loading") {
      return <Loader className={styles.loader} />;
    }
    if (store.state instanceof ErrorState) {
      return (
        <>
          <Status type="error" backUrl={details.backUrl}>
            <div>Hmm, we can&apos;t find</div>
            <div>who this link belongs to.</div>
            <div>Please, check if you copied</div>
            <div>the address correctly.</div>
          </Status>
          <Blocked
            name={details.toAccountName}
            accountNumber={details.openbankingPaymentRequest.toAccountNumber}
            sortCode={details.openbankingPaymentRequest.toAccountSortCode}
          />
        </>
      );
    }

    const { config } = store.state;

    return (
      <div className={`${styles.panelBanks} ${bankToShow && styles.overlay}`}>
        {bankToShow && (
          <div className={styles.confirmationOverlay}>
            {store.redirectUrl && (
              <div className={styles.confirmationLink}>
                If you are not redirected,
                <a href={store.redirectUrl}>tap here</a>
              </div>
            )}
            <div className={styles.confirmationBlock}>
              <div className={styles.confirmationText}>You will now be redirected to {bankToShow.displayable_name}</div>
              <div
                className={styles.confirmationButtonPrimary}
                onClick={() => handleConfirm(config.paymentId, config.token, bankToShow.id)}
              >
                Confirm
              </div>
              <div className={styles.confirmationButton} onClick={() => selectBank(null)}>
                Cancel
              </div>
            </div>
          </div>
        )}
        <div className={styles.panelBanksTitle}>Choose your bank to make this payment</div>
        <div className={styles.panelBanksSearch}>
          <input placeholder="Search" value={query} onChange={(event) => setQuery(event.target.value)} />
          {!query && <img alt="" src={iconSearch} className={styles.panelBanksSearchIcon} />}
          {query && <img alt="" src={iconClear} className={styles.panelBanksClearIcon} onClick={() => setQuery("")} />}
        </div>
        <div className={styles.banksListContainer}>
          {store.state.list.length === 0 && (
            <div className={styles.banksListEmpty}>
              There are no results for &quot;{query}&quot;
              <br />
              Try searching for another or choose from the list of available payment methods.
            </div>
          )}
          {!!store.state.list.length && (
            <div className={styles.banksListContainerContent}>
              {store.state.list.map((bank) => (
                <div key={bank.id} className={styles.panelBanksItemBlock} onClick={() => selectBank(bank)}>
                  {bank.label === "business" && (
                    <div className={styles.stickerBox}>
                      <div className={styles.sticker}>BUSINESS</div>
                    </div>
                  )}
                  {bank.label === "corporate" && (
                    <div className={styles.stickerBox}>
                      <div className={styles.corporateSticker}>CORPORATE</div>
                    </div>
                  )}
                  <img src={bank.logo} alt={bank.displayable_name} />
                </div>
              ))}
            </div>
          )}
        </div>
        {!!store.state.list.length && (
          <div className={styles.banksListFooterContainer}>
            <Policy raw paymentType="banks" className={styles.banksListFooter} />
          </div>
        )}
      </div>
    );
  };

  return <>{render()}</>;
});
