import React, { VFC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, Text } from "@anna-money/anna-web-ui";
import copy from "copy-to-clipboard";
import { message } from "antd";
import classNames from "classnames";
import styles from "./contactsDetailsSection.module.scss";
import { MapComponent } from "../../components/businessLocationSection/mapComponent/mapComponent";
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, PhoneIcon, SiteIcon } from "../../../../icons/icons";
import { daysLong, getDayInfo } from "../../components/openingHoursSection/openingHoursSection";
import { FormSectionType } from "../obPaymentPageView";

export const ContactsDetailsSection: VFC<FormSectionType> = observer(({ state }) => {
  const {
    logo,
    backgroundImage,
    workingHours,
    facebookLink,
    linkedinLink,
    instagramLink,
    address,
    beneficiaryName,
    contactPhone,
    contactEmail,
    contactWebsite,
    googleMapsPlaceId,
  } = state;
  const hasContacts = !!contactPhone || !!contactEmail || !!contactWebsite;
  const hasSocialMediaContacts = !!facebookLink || !!linkedinLink || !!instagramLink;

  const handleCopy = () => {
    copy(`https://www.google.com/maps/place/?q=place_id:${googleMapsPlaceId}`);
    message.success("Address has been copied to clipboard");
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        !logo && !backgroundImage && styles.marginLarge,
        !backgroundImage && logo && styles.marginSmall
      )}
    >
      {!!state.googleMapsPlaceId && <MapComponent placeId={googleMapsPlaceId} />}
      {!!address && (
        <div className={styles.addressSection}>
          <div className={styles.address}>
            <span className={styles.subtitle}>{beneficiaryName}</span>
            <span className={styles.text}>{address}</span>
          </div>
          <IconButton icon="actionShare" iconColor="themeGreen" onClick={handleCopy} />
        </div>
      )}
      {hasContacts && (
        <div className={styles.contactsSection}>
          {contactPhone && (
            <div className={styles.rowContent}>
              <PhoneIcon width="24" height="24" color="#12ad84" />
              <a className={styles.textLink} href={`tel:${contactPhone}`}>
                {contactPhone}
              </a>
            </div>
          )}

          {contactEmail && (
            <div className={styles.rowContent}>
              <EmailIcon width="24" height="24" color="#12ad84" />
              <a className={styles.textLink} href={`mailto:${contactEmail}`}>
                {contactEmail}
              </a>
            </div>
          )}

          {contactWebsite && (
            <div className={styles.rowContent}>
              <SiteIcon width="24" height="24" color="#12ad84" />
              <a rel="noreferrer" target="_blank" className={styles.textLink} href={contactWebsite}>
                {contactWebsite}
              </a>
            </div>
          )}
        </div>
      )}
      {hasSocialMediaContacts && (
        <div className={styles.socialMediaSection}>
          {instagramLink && (
            <div className={styles.rowContent}>
              <a href={instagramLink}>
                <InstagramIcon height="27" width="27" color="#12ad84" />
              </a>
            </div>
          )}
          {facebookLink && (
            <div className={styles.rowContent}>
              <a href={facebookLink}>
                <FacebookIcon height="27" width="27" color="#12ad84" />
              </a>
            </div>
          )}
          {linkedinLink && (
            <div className={styles.rowContent}>
              <a href={linkedinLink}>
                <LinkedinIcon height="27" width="27" color="#12ad84" />
              </a>
            </div>
          )}
        </div>
      )}
      {!!workingHours?.length && (
        <div className={styles.workingHoursSection}>
          <span className={styles.subtitle}>Opening hours</span>
          {daysLong.map((day, i) => {
            const ind = `view-${i}`;
            const dayData = workingHours.find((el) => el.weekday === i + 1);
            return (
              <div className={styles.rowContent} key={ind}>
                <Text weight="semibold" className={styles.text} type="body1">
                  {day}
                </Text>

                <Text weight="semibold" className={styles.hours} type="body1">
                  {getDayInfo(dayData)}
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
