import React, { AttachedListItem, CustomDropdown, DropdownButton, DropdownMenuBody } from "@anna-money/anna-web-ui";
import { FC, useState } from "react";
import classNames from "classnames";
import { FieldGroupOrder } from "@anna-money/anna-web-ui/dist/components/Forms/FieldsGroup/FieldsGroup";
import styles from "./Dropdown.module.scss";
import { DropdownOption } from "../../sections/obPaymentPage/helpers";
import { Label } from "../label/label";

type ValueType = string | undefined | number;

type DropdownProps = {
  value: ValueType;
  options: DropdownOption[];
  label?: string;
  className?: string;
  hasError?: boolean;
  fieldGroupPosition?: FieldGroupOrder;
  onChange?: (value: ValueType) => void;
};

export const Dropdown: FC<DropdownProps> = ({
  options,
  value,
  onChange,
  label,
  hasError,
  fieldGroupPosition,
  className,
}: DropdownProps) => {
  const [open, toggle] = useState(false);
  const selectedOption = options.find((option) => value === option.value);

  const handleSelect = (selectedValue: ValueType): void => {
    onChange(selectedValue);
    toggle(false);
  };

  return (
    <CustomDropdown open={open} onClose={() => toggle(false)} className={classNames(styles.wrapper, className)}>
      {label && <Label labelText={label} />}
      <DropdownButton
        open={open}
        fieldGroupPosition={fieldGroupPosition}
        hasError={hasError}
        onClick={() => toggle(!open)}
        label={selectedOption?.label || "--"}
        className={styles.dropdownButton}
      />
      {open && (
        <DropdownMenuBody className={styles.body}>
          {options.map((option) => (
            <AttachedListItem
              key={option.label}
              label={<span className={styles.label}>{option.label}</span>}
              onClick={() => handleSelect(option.value)}
              selected={option.value === value}
            />
          ))}
        </DropdownMenuBody>
      )}
    </CustomDropdown>
  );
};
