import get from "lodash.get";
import set from "lodash.set";
import { makeAutoObservable } from "mobx";

enum ValidationMessages {
  required = "Required",
}

type ValidationResult = {
  isValid: boolean;
  message: string;
};

type ValidationRule = {
  check: (v: unknown) => boolean;
  message?: ValidationMessages | string;
};

export type ValidationConfig = {
  [key: string]: {
    rules: ValidationRule[];
  };
};

type Errors = { [key: string]: string };

// eslint-disable-next-line
export class Form<T extends Record<string, any>> {
  private readonly _values: T;

  private _errors: Errors = {};

  private readonly _config: ValidationConfig;

  constructor(values: T, config: ValidationConfig = {}) {
    this._values = values;
    this._config = config;
    makeAutoObservable(this);
  }

  get values(): T {
    return this._values;
  }

  // eslint-disable-next-line
  getValue(field: string): any {
    return get(this.values, field);
  }

  private get errors(): Errors {
    return this._errors;
  }

  private set errors(errors: Errors) {
    this._errors = errors;
  }

  getErrors = (): Errors => this._errors;

  onChange<K>(field: string, value: K): void {
    set(this.values, field, value);
    if (this._config[field]) {
      set(this.errors, field, "");
    }
  }

  // eslint-disable-next-line
  onMultipleChange(fields: Array<{ field: string; value: any }>): void {
    fields.forEach(({ field, value }) => {
      this.onChange(field, value);
    });
  }

  getError(field: string): string {
    return get(this.errors, field);
  }

  // eslint-disable-next-line
  private readonly _checkField = (field: string, value: any): string => {
    let results: ValidationResult[] = [];
    if (this._config[field]) {
      results = this._config[field].rules
        .map(({ check, message }) => ({
          isValid: check(value),
          message: message || ValidationMessages.required,
        }))
        .filter((result) => !result.isValid);
    }
    return results.length ? results[0].message : "";
  };

  isFieldValid<D>(field: string, value: D): boolean {
    const errors: Errors = { ...this.errors };
    if (this._config[field]) {
      errors[field] = this._checkField(field, value);
    }
    this.errors = errors;

    return !this.errors[field];
  }

  get hasErrors(): boolean {
    return Object.values(this.errors).filter((e) => !!e).length > 0;
  }

  isValid(): boolean {
    Object.keys(this._config).forEach((field) => {
      this.isFieldValid(field, this.getValue(field));
    });
    return !this.hasErrors;
  }
}
