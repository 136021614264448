import { makeAutoObservable } from "mobx";
import { createPaymentDetails, PaymentApiClient } from "../../api/payment.api.client";
import { ErrorState } from "../../helpers/error";
import { PaymentInfo } from "../../types/payment";

type PaymentStoreState = "loading" | ErrorState | PaymentInfo;

export class PaymentStore {
  private readonly _api: PaymentApiClient;

  private _state: PaymentStoreState = "loading";

  constructor(client: PaymentApiClient) {
    makeAutoObservable(this);
    this._api = client;
  }

  get state(): PaymentStoreState {
    return this._state;
  }

  set state(state: PaymentStoreState) {
    this._state = state;
  }

  async load(paymentRequestId?: string): Promise<void> {
    if (!paymentRequestId) {
      this.state = new ErrorState("Payment request id is not defined");
      return;
    }

    try {
      const data = await this._api.getTransactionInfo(paymentRequestId);
      this.state = createPaymentDetails(data);
    } catch (e) {
      this.state = new ErrorState("Failed to request payment details");
      throw e;
    }
  }
}
