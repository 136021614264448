import React, { FC, useEffect, useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { Button, Icon, IconButton, Loader, Text, UploadZone, UploadZoneProcessState } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import styles from "./HeaderSection.module.scss";
import { ImageIcon } from "../../../../icons/icons";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { Form } from "../../../../components/form/form";
import { ObPaymentPageFormStore } from "../../obPaymentPageFormStore";
import { CompanyLogoSection } from "../companyLogoSection/companyLogoSection";
import { PaymentPageForm } from "../../../../types/paymentPage";
import { ErrorView } from "../../../../components/errorView/errorView";

const imageTypes = ["image/jpeg", "image/png", "image/gif"];

type ImageUploaderProps = {
  className?: string;
  initialPreview?: string | null;
  form: Form<PaymentPageForm>;
  modalForm: Form<{ backgroundImage: string; logo: string; switchedOnLogo: boolean }>;
  store: ObPaymentPageFormStore;
};

export const ImageUploaderSection: FC<ImageUploaderProps> = observer(
  ({ initialPreview = null, store, form, modalForm }) => {
    const [preview, setPreview] = useState<string | null>(initialPreview);
    const [file, setFile] = useState<File>();
    const [state, onProcessStateChange] = useState<UploadZoneProcessState>("ready");

    const [editMode, setEditMode] = useState(false);

    const handleSelect = (fileValue, formData) => {
      form.onChange("backgroundImageFile", fileValue);
      form.onChange("backgroundImageFileData", null);

      if (fileValue) {
        const reader = new FileReader();
        reader.onloadend = () => {
          formData.onChange("backgroundImage", reader.result);
          store.clearValidationError("imageSize");
        };
        reader.readAsDataURL(fileValue);
      }
    };

    const onSelectHandler = async (fileValue: File, formData): Promise<void> => {
      setFile(fileValue);
      await handleSelect(fileValue, formData);
    };

    const onSelectLogoHandler = async (fileValue: File): Promise<void> => {
      form.onChange("logoFile", fileValue);
      form.onChange("logoFileData", null);
      if (fileValue) {
        const reader = new FileReader();
        reader.onloadend = () => {
          modalForm.onChange("logo", reader.result);
          store.clearValidationError("imageSize");
        };
        reader.readAsDataURL(fileValue);
      }
    };

    useEffect(() => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }, [file]);

    const onRemoveHandler = (e): void => {
      form.onChange("backgroundImageFile", null);
      form.onChange("backgroundImageFileData", null);
      e?.stopPropagation();
      setPreview(null);
      modalForm.onChange("backgroundImage", null);
      store.clearValidationError("imageSize");
    };

    const renderContent = (): React.ReactNode => {
      if (state === "error") {
        return (
          <Button className={styles.retryAction} type="text" prefix={<Icon type="actionRefresh" />} text="Try again" />
        );
      }

      if (state === "uploading") {
        return <Loader />;
      }

      return (
        <div className={styles.content}>
          {preview ? (
            <>
              <div
                className={styles.preview}
                style={{
                  backgroundImage: `url(${preview})`,
                }}
              />
              {editMode ? (
                <div className={styles.buttonsBlock}>
                  <div className={styles.changeButton}>
                    <UploadZone
                      onSelect={(fileValue) => onSelectHandler(fileValue, modalForm)}
                      className={styles.changeUploader}
                    >
                      <IconButton className={styles.icon} icon="actionEdit" type="primary" size="s" />
                      <Text weight="semibold" type="body1" color="themeGreen">
                        Change
                      </Text>
                    </UploadZone>
                  </div>
                  <div className={styles.removeButton}>
                    <IconButton
                      className={styles.icon}
                      icon="actionClose"
                      type="primary"
                      size="l"
                      onClick={(e) => {
                        onRemoveHandler(e);
                      }}
                    />
                    <Text weight="semibold" type="body1" color="themeGreen">
                      Remove
                    </Text>
                  </div>
                </div>
              ) : (
                <IconButton
                  className={styles.editButton}
                  icon="actionEdit"
                  type="primary"
                  size="s"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditMode(true);
                  }}
                />
              )}
            </>
          ) : (
            <>
              <ImageIcon width="37" height="37" />
              <div className={styles.label}>+ Add hero image</div>
            </>
          )}
        </div>
      );
    };

    const handleSave = () => {
      form.onMultipleChange([
        { field: "backgroundImage", value: modalForm.getValue("backgroundImage") },
        { field: "logo", value: modalForm.getValue("logo") },
        { field: "switchedOnLogo", value: modalForm.getValue("switchedOnLogo") },
      ]);
      setEditMode(false);
    };

    const handleCancel = () => {
      modalForm.onMultipleChange([
        { field: "backgroundImage", value: form.getValue("backgroundImage") },
        { field: "logo", value: form.getValue("logo") },
        { field: "switchedOnLogo", value: form.getValue("switchedOnLogo") },
      ]);
      setEditMode(false);
    };

    const renderUploadZone = () => (
      <UploadZone
        accept={imageTypes}
        multiple={false}
        className={classNames(styles.wrapper, styles["theme-default"], styles.uploader)}
        onStateChange={onProcessStateChange}
        onSelect={(fileValue) => onSelectHandler(fileValue, form)}
      >
        {renderContent()}
      </UploadZone>
    );

    const renderLogoSection = () => {
      const logo = modalForm.getValue("logo") as string;
      return (
        <div className={classNames(styles.logoWrapper, editMode && preview && styles.withSeparator)}>
          {logo ? (
            <>
              <span className={styles.sectionSubtitle}>Company logo</span>
              <div className={styles.logo}>
                <img className={styles.headerLogo} src={logo} alt="" />
                <IconButton
                  icon="actionCloseCircle"
                  className={styles.iconClose}
                  iconColor="themeGreen"
                  size="s"
                  onClick={() => {
                    form.onChange("logoFile", null);
                    form.onChange("logoFileData", null);
                    modalForm.onChange("logo", null);
                    store.clearValidationError("imageSize");
                  }}
                />
              </div>
              <UploadZone onSelect={onSelectLogoHandler} className={styles.uploadZoneButton}>
                <Button
                  className={styles.addButton}
                  text="Choose another logo"
                  type="text"
                  prefix={<Icon type="actionAdd" />}
                />
              </UploadZone>
            </>
          ) : (
            <CompanyLogoSection store={store} form={form} modalForm={modalForm} withSeparator={false} view="modal" />
          )}
        </div>
      );
    };

    return (
      <>
        {!editMode ? (
          renderUploadZone()
        ) : (
          <EditFormModalSection open={editMode} onSave={handleSave} onCancel={handleCancel} withMargin={false}>
            <div>
              {renderUploadZone()}
              {renderLogoSection()}
            </div>
          </EditFormModalSection>
        )}
      </>
    );
  }
);

type HeaderSectionProps = {
  modalForm: Form<{ backgroundImage: string; logo: string; switchedOnLogo: boolean }>;
  form: Form<PaymentPageForm>;
  store: ObPaymentPageFormStore;
};

export const HeaderSection: VFC<HeaderSectionProps> = observer(({ form, store, modalForm }) => {
  const [showBanner, toggleShowBanner] = useState(store.firstUse);

  return (
    <>
      <div className={styles.headerSection}>
        <span className={styles.pageTitle}>Your payment page</span>
        {showBanner && (
          <div className={styles.banner}>
            <span className={styles.text}>
              Here&apos;s what appears on your page. Update the info you want to show, or hide the bits you don&apos;t
              need
            </span>
            <IconButton onClick={() => toggleShowBanner(false)} iconColor="themeGreen" icon="actionClose" />
          </div>
        )}
        <div className={styles.header}>
          <>
            <ImageUploaderSection
              form={form}
              modalForm={modalForm}
              store={store}
              initialPreview={form.getValue("backgroundImage") as string}
            />
          </>
          {!!form.getValue("logo") && (
            <div className={styles.logoBlock}>
              <>
                <img className={styles.headerLogo} src={form.getValue("logo")} alt="" />
                <IconButton
                  icon="actionCloseCircle"
                  className={styles.iconClose}
                  iconColor="themeGreen"
                  size="s"
                  onClick={() => {
                    form.onChange("logoFile", null);
                    form.onChange("logoFileData", null);
                    form.onChange("logo", null);
                    modalForm.onChange("logo", null);
                    store.clearValidationError("imageSize");
                  }}
                />
              </>
            </div>
          )}
        </div>

        {!!store.getValidationError("imageSize") && (
          <div id="imageSize" className={classNames(styles.errorView, form.getValue("logo") && styles.withMargin)}>
            <ErrorView>{store.getValidationError("imageSize")}</ErrorView>
          </div>
        )}
      </div>
    </>
  );
});
