import { Route } from "react-router-dom";
import React from "react";
import { PaymentLink } from "./paymentLink";

export const paymentLinkRoutes = (
  <>
    <Route path="/p/:paymentTemplateId/:productId" element={<PaymentLink />} />
    <Route path="/p/:paymentTemplateId" element={<PaymentLink />} />
  </>
);
