import React, { ReactNode, useCallback, useEffect, useMemo, VFC } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import dateformat from "dateformat";
import { Icon } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { useServiceFactory, useServices } from "../../app/appServices";
import { useMount } from "../../helpers/reactHooks";
import { Status } from "../../components/status/status";
import { ErrorState } from "../../helpers/error";
import styles from "./InvoiceLink.module.scss";
import { downloadInvoice, InvoiceLinkData } from "./invoiceLinkStore";
import { Button } from "../../components/button/button";
import { PdfViewer } from "../../components/pdfViewer/pdfViewer";
import { formatMoney } from "../../helpers/formatMoney";
import { invoiceLinkAnalyticsEvents } from "../../api/analytics.api.client";

type FooterVariation = {
  title: string;
  description: string;
  linkTitle: string;
  linkUrl: string;
}

const footerVariations: FooterVariation[] = [
  {
    title: "The AI-powered business account that calculates your taxes",
    description: "Open an ANNA business account in minutes",
    linkTitle: "Try it for free",
    linkUrl: "https://go.anna.money/web/?utm_source=invoice_link&utm_campaign=invoicing_v1",
  },
  {
    title: "The smart business account with AI tax calculation and filing",
    description: "Open an ANNA business account in minutes",
    linkTitle: "Try it for free",
    linkUrl: "https://go.anna.money/web/?utm_source=invoice_link&utm_campaign=invoicing_v2",
  },
  {
    title: "Better than just a business bank account",
    description: "The business account with AI tax calculation and filing",
    linkTitle: "Learn more",
    linkUrl: "https://go.anna.money/web/?utm_source=invoice_link&utm_campaign=invoicing_v3",
  },
  {
    title: "Register a company with ANNA for free",
    description: "Get your banking and taxes sorted from day one",
    linkTitle: "Learn more",
    linkUrl: "https://anna.money/register-a-company/?utm_source=invoice_link&utm_campaign=invoicing_v4",
  },
];

export const InvoiceLink: VFC = observer(() => {
  const { annaAnalyticsClient: analytics } = useServices();
  const store = useServiceFactory((x) => x.createInvoiceLinkStore());
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { secureId } = params;

  useMount(() => store.load(secureId, searchParams.get("payment_id")));

  const footerVariationIndex = useMemo(() => Math.floor(Math.random() * footerVariations.length), []);

  useEffect(() => {
    void analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.footerVariation, {
      variation: footerVariationIndex,
    });
  }, [footerVariationIndex]);

  const footerVariationActivated = useCallback(() => {
    void analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.footerVariationActivation, {
      variation: footerVariationIndex
    });
  }, [footerVariationIndex]);

  const handleClickDownloadReceiptPdf = async (state: InvoiceLinkData): Promise<void> => {
    await analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.downloadReceiptPdf, {
      secure_id: state.secureId,
    });
    await downloadInvoice(state.invoice.invoicePdf, state.invoice.textNumber);
  };

  const handleClickDownloadInvoicePdf = async (state: InvoiceLinkData): Promise<void> => {
    await analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.downloadInvoicePdf, {
      secure_id: state.secureId,
    });
    await downloadInvoice(state.invoice.invoicePdf, state.invoice.textNumber);
  };

  const handleClickRefreshPage = async (state: InvoiceLinkData): Promise<void> => {
    await analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.refreshPage, { secure_id: state.secureId });
    await store.load(state.secureId, state.paymentId);
  };

  const handleClickPayInvoice = async (state: InvoiceLinkData): Promise<void> => {
    await analytics.postAnnaAnalyticsEvent(invoiceLinkAnalyticsEvents.pay, { secure_id: state.secureId });
  };

  const renderFooter = (className: string, { title, description, linkTitle, linkUrl }: FooterVariation): ReactNode => (
    <div className={classNames(styles.footer, className)}>
      <div className={styles.footerAnnaLogo} />
      <div className={styles.footerTitle}>{title}</div>
      <div className={styles.footerDescription}>{description}</div>
      <Button
        suffix={<Icon type="actionArrowRight" />}
        type="link"
        href={linkUrl}
        onClick={footerVariationActivated}
      >
        {linkTitle}
      </Button>
    </div>
  );

  const renderHeaderLogo = (companyLogo: string): ReactNode => (
    <div className={styles.imageContainer}>
      {companyLogo && <img className={styles.image} src={companyLogo} alt="logo" />}
    </div>
  );

  const renderPaidInvoice = (state: InvoiceLinkData): ReactNode => (
    <>
      <div className={styles.header}>
        {renderHeaderLogo(state.invoice.companyLogo)}

        <div className={styles.headerTitle}>
          <div className={styles.yourInvoice}>
            Thanks, Invoice #{state.invoice.textNumber}
            <br />
            has been paid
          </div>
        </div>
      </div>

      <div className={styles.downloadReceiptButton}>
        <Button onClick={() => handleClickDownloadReceiptPdf(state)}>Download receipt</Button>
      </div>
    </>
  );

  const renderPreviewInvoicePdf = (state: InvoiceLinkData): ReactNode => {
    if (state.invoice.invoicePdf) {
      return (
        <div className={styles.pdfPreview}>
          <PdfViewer fileUrl={state.invoice.invoicePdf} documentClassName={styles.document} />
        </div>
      );
    }

    return null;
  };

  const renderPaymentPending = (state: InvoiceLinkData): ReactNode => (
    <>
      <div className={styles.header}>
        {renderHeaderLogo(state.invoice.companyLogo)}

        <div className={styles.headerTitle}>
          <div className={styles.wrapper}>
            <div className={styles.paymentProcessing}>Payment processing ...</div>
          </div>

          <div className={styles.paymentDescription}>We need a few moments to process this payment</div>
        </div>
      </div>

      <div className={styles.description}>To refresh this page, tap the button below</div>

      <div className={styles.refreshPageButton}>
        <Button onClick={() => handleClickRefreshPage(state)}>Refresh page</Button>
      </div>
    </>
  );

  const renderPaymentError = (state: InvoiceLinkData): ReactNode => (
    <>
      <div className={styles.header}>
        {renderHeaderLogo(state.invoice.companyLogo)}

        <div className={styles.headerTitle}>
          <div className={styles.paymentError}>Payment error</div>
          <div className={styles.paymentDescription}>
            Something went wrong with your payment for Invoice #{state.invoice.textNumber}
          </div>
        </div>
      </div>

      <div className={styles.description}>Please try making the payment again by tapping the button below</div>

      {state.invoice.paymentUrl && (
        <div className={styles.payButton}>
          <a href={state.invoice.paymentUrl} onClick={() => handleClickPayInvoice(state)}>
            Pay {formatMoney(+state.invoice.total, state.invoice.currency)}
          </a>
        </div>
      )}
    </>
  );

  const renderIssuedInvoice = (state: InvoiceLinkData): ReactNode => (
    <>
      <div className={styles.header}>
        {renderHeaderLogo(state.invoice.companyLogo)}

        <div className={styles.headerTitle}>
          <div className={styles.yourInvoice}>Here&apos;s your invoice from</div>
          <div className={styles.companyTitle}>{state.invoice.companyTitle}</div>
        </div>
      </div>

      <div className={styles.invoiceInfo}>
        <div className={styles.invoiceInfoItem}>
          <b>#{state.invoice.textNumber}</b>
        </div>
        <div className={styles.invoiceInfoItem}>
          <b>To pay:</b> {formatMoney(+state.invoice.total, state.invoice.currency)}
        </div>
        <div className={styles.invoiceInfoItem}>
          <b>Due by:</b> {dateformat(state.invoice.dueDate, "dd mmm yyyy")}
        </div>
      </div>

      {state.invoice.paymentUrl && (
        <div className={styles.description}>
          Ready to pay this invoice? Tap the button below – there&apos;s no need to create an account, and it&apos;s
          totally free to use.
        </div>
      )}

      {state.invoice.paymentUrl && (
        <div className={styles.payButton}>
          <Button href={state.invoice.paymentUrl}>
            Pay {formatMoney(+state.invoice.total, state.invoice.currency)}
          </Button>
        </div>
      )}

      <div className={styles.downloadPdfButton}>
        <Button type="link" onClick={() => handleClickDownloadInvoicePdf(state)}>
          Download invoice PDF
        </Button>
      </div>
    </>
  );

  const renderInvoice = (state: InvoiceLinkData): ReactNode => {
    if (state.invoice.status === "paid") {
      return renderPaidInvoice(state);
    }

    if (state.invoice.paymentStatus === "pending") {
      return renderPaymentPending(state);
    }

    if (state.paymentId && state.invoice.paymentStatus === "error") {
      return renderPaymentError(state);
    }

    return renderIssuedInvoice(state);
  };

  if (store.state === "loading") {
    return <Status type="progress" />;
  }

  if (store.state instanceof ErrorState) {
    return <Status type="error" text={store.state.message} />;
  }

  const footerVariation = footerVariations[footerVariationIndex];

  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <div className={styles.mainContentCenter}>{renderInvoice(store.state)}</div>
        {renderFooter(styles.desktopFooter, footerVariation)}
      </div>
      <div className={styles.sideContent}>
        {renderPreviewInvoicePdf(store.state)}
        {renderFooter(styles.mobileFooter, footerVariation)}
      </div>
    </div>
  );
});
