import copy from "copy-to-clipboard";
import { message, Modal } from "antd";
import React, { FC, useState } from "react";
import Logo from "../../resources/annaLogo.svg";

import styles from "./Blocked.module.scss";

type BlockedProps = {
  name: string;
  accountNumber: string;
  sortCode: string;
};

export const Blocked: FC<BlockedProps> = ({ name, accountNumber, sortCode }) => {
  const [visible, toggleVisible] = useState(true);
  const handleCopy = () => {
    copy(`${name}\nAccount number: ${accountNumber}\nSort code: ${sortCode}`);
    message.success("Bank details has been copied to clipboard");
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.blocked}>
      <Modal className={styles.modal} visible centered closable={false} footer={null} width={500}>
        <div className={styles.header}>
          <img src={Logo} alt="logo" />
        </div>
        <div className={styles.message}>
          Sorry, this payment paymentLink is unavailable right now, but you can copy their banks details and use them to
          pay another way.
        </div>
        <div className={styles.buttons}>
          <div className={styles.copyDetailsButton} onClick={handleCopy}>
            Copy bank details
          </div>
          <div className={styles.closeButton} onClick={() => toggleVisible(false)}>
            Close
          </div>
        </div>
      </Modal>
    </div>
  );
};
