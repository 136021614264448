import React, { VFC } from "react";
import { Button, Icon, UploadZone } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import styles from "./companyLogoSection.module.scss";
import { Toggle } from "../../../../components/toggle/toggle";
import { ObPaymentPageFormStore } from "../../obPaymentPageFormStore";
import { Form } from "../../../../components/form/form";
import { PaymentPageForm } from "../../../../types/paymentPage";

type CompanyLogoSectionType = {
  form: Form<PaymentPageForm>;
  modalForm: Form<{ backgroundImage: string; logo: string; switchedOnLogo: boolean }>;
  store: ObPaymentPageFormStore;
  withSeparator?: boolean;
  view?: "modal" | "page";
};

export const CompanyLogoSection: VFC<CompanyLogoSectionType> = observer(
  ({ form, modalForm, withSeparator = true, view = "page" }) => {
    const formData = view === "modal" ? modalForm : form;
    const { switchedOnLogo } = formData.values;

    const handleToggleChange = (value: boolean) => {
      formData.onChange("switchedOnLogo", value);
      if (view === "page") {
        modalForm.onChange("switchedOnLogo", value);
      }
      if (!value) {
        formData.onChange("logo", null);
        form.onChange("logoFile", null);
        form.onChange("logoFileData", null);
      }
    };

    const onSelectHandler = async (file: File): Promise<void> => {
      form.onChange("logoFile", file);
      form.onChange("logoFileData", null);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          form.onChange("logo", reader.result);
          modalForm.onChange("logo", reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    if (form.getValue("logo") && modalForm.getValue("logo")) {
      return null;
    }

    return (
      <div className={classNames(styles.section, withSeparator && styles.withSeparator)}>
        <div className={styles.companyLogoSection}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionTitle}>
              <span className={styles.sectionSubtitle}>Company logo</span>
              <span className={styles.sectionDescription}>Upload your company logo for a more personalised page</span>
              {switchedOnLogo && <span className={styles.lightText}>Max. file size 100kb. JPEG or PNG files only</span>}
            </div>
            <Toggle key="2" value={switchedOnLogo} onChange={handleToggleChange} className={styles.sectionTitle} />
          </div>
          {switchedOnLogo && (
            <UploadZone onSelect={onSelectHandler}>
              <Button className={styles.addButton} text="Add logo" type="text" prefix={<Icon type="actionAdd" />} />
            </UploadZone>
          )}
        </div>
      </div>
    );
  }
);
