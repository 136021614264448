import React, { ChangeEventHandler, FocusEventHandler, forwardRef, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import { FieldGroupOrder } from "@anna-money/anna-web-ui/dist/components/Forms/FieldsGroup/FieldsGroup";
import { FieldError } from "@anna-money/anna-web-ui";
import styles from "./Input.module.scss";
import { Label } from "../label/label";

type TextFieldProps = {
  className?: string;
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  fieldGroupPosition?: FieldGroupOrder;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: "text" | "number";
  disabled?: boolean;
  id?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  min?: number;
  max?: number;
  step?: string;
  error?: string;
  prefix?: ReactNode;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  autoCapitalize?: HTMLAttributes<HTMLInputElement>["autoCapitalize"];
};

export const Input = forwardRef<HTMLSpanElement, TextFieldProps>(
  (
    {
      className,
      placeholder,
      value,
      error,
      onChange,
      label,
      hasError = false,
      type = "text",
      disabled,
      prefix,
      fieldGroupPosition,
      inputMode,
      autoCapitalize,
      ...rest
    },
    ref
  ) => (
    <span ref={ref} className={classNames(styles.wrapper, className)}>
      {!!label && <Label labelText={label} />}
      <span ref={ref} className={classNames(styles.textFieldContainer)}>
        {!!prefix && <span className={styles.prefix}>{prefix}</span>}
        <input
          className={classNames(
            styles.textField,
            { [styles.error]: hasError },
            styles[`type-${type}`],
            { [styles.hasPrefix]: !!prefix },
            fieldGroupPosition && styles[fieldGroupPosition]
          )}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          inputMode={inputMode}
          autoCapitalize={autoCapitalize}
          {...rest}
        />
      </span>
      {!!error && <FieldError className={styles.errorField} errorText={error} />}
    </span>
  )
);
