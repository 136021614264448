export const currencySymbol: Record<string, string> = {
  GBP: "£",
  AOA: "Kz",
  AUD: "$",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BMD: "$",
  BND: "$",
  BOB: "Bs",
  BSD: "$",
  BWP: "P",
  BYN: "р.",
  BZD: "$",
  CAD: "$",
  CNY: "¥",
  CRC: "₡",
  CZK: "Kč",
  DKK: "kr",
  EGP: "£E",
  ESP: "₧",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GIP: "£",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  ISK: "kr",
  JMD: "$",
  JPY: "¥",
  KHR: "៛",
  KPW: "₩",
  KRW: "₩",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£L",
  LKR: "Rs",
  LRD: "$",
  LTL: "Lt",
  LVL: "Ls",
  MGA: "Ar",
  MMK: "K",
  MNT: "₮",
  MUR: "Rs",
  MYR: "RM",
  NAD: "$",
  NGN: "₦",
  NOK: "kr",
  NPR: "Rs",
  NZD: "$",
  PHP: "₱",
  PKR: "Rs",
  PLN: "zł",
  PYG: "₲",
  RUB: "₽",
  RUR: "р.",
  SBD: "$",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  SYP: "£",
  THB: "฿",
  TOP: "$T",
  TRY: "₺",
  TTD: "$",
  TWD: "$",
  UAH: "₴",
  USD: "$",
  VEF: "Bs",
  VND: "₫",
  XCD: "$",
  ZAR: "R",
};
