import React, { FC, ReactNode } from "react";

type ErrorViewProps = {
  children?: ReactNode;
};

export const ErrorView: FC<ErrorViewProps> = ({ children }) => {
  if (children) {
    return <>{children}</>;
  }

  return <div>Something went wrong</div>;
};
