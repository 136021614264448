import React, { ChangeEvent, useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { FieldError, IconButton } from "@anna-money/anna-web-ui";
import classnames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import styles from "./contactDetailsSection.module.scss";
import { Toggle } from "../../../../components/toggle/toggle";
import { EmailIcon, PhoneIcon, SiteIcon } from "../../../../icons/icons";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { useFactory } from "../../../../helpers/reactHooks";
import { Form } from "../../../../components/form/form";
import { contactsDetailsValidationConfig } from "../../validation";
import { Input } from "../../../../components/input/input";

export const ContactDetailsSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const { contactWebsite, contactPhone, contactEmail, switchedOnContactsDetails } = form.values;
  const modalForm = useFactory(
    () =>
      new Form(
        { contactWebsite, contactPhone, contactEmail, switchedOnContactsDetails },
        contactsDetailsValidationConfig
      )
  );
  const [isFilled, setFilled] = useState(!!contactWebsite || !!contactPhone || !!contactEmail);
  const [editMode, setEditMode] = useState(!isFilled);

  const renderViewContent = () => (
    <div className={styles.viewSection}>
      <div className={styles.contactDetailsSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionViewTitle}>
            <span className={styles.sectionSubtitle}>Contact details</span>
          </div>

          <IconButton icon="actionEdit" iconColor="themeGreen" size="s" onClick={() => setEditMode(true)} />
        </div>
        <div className={styles.sectionViewContent}>
          {contactPhone && (
            <div className={styles.rowContent}>
              <PhoneIcon width="17" height="17" />
              <span className={styles.text}>{contactPhone}</span>
            </div>
          )}
          {contactEmail && (
            <div className={styles.rowContent}>
              <EmailIcon width="17" height="17" />
              <span className={styles.text}>{contactEmail}</span>
            </div>
          )}
          {contactWebsite && (
            <div className={styles.rowContent}>
              <SiteIcon width="17" height="17" />
              <span className={styles.text}>{contactWebsite}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const handleChangeToggle = (value, formData) => {
    formData.onChange("switchedOnContactsDetails", value);
    if (!value) {
      formData.onChange("contactWebsite", "");
      formData.onChange("contactPhone", "");
      formData.onChange("contactEmail", "");
    }
  };

  const renderForm = (formData) => (
    <div className={styles.amountSection}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>
          <span className={styles.sectionSubtitle}>Contact details</span>
          <span className={styles.sectionDescription}>Include ways of how your customer can reach you</span>
        </div>
        <Toggle
          value={formData.getValue("switchedOnContactsDetails")}
          onChange={(value) => handleChangeToggle(value, formData)}
        />
      </div>

      {formData.getValue("switchedOnContactsDetails") && (
        <>
          <Input
            label="Phone"
            className={styles.field}
            placeholder="+44"
            id="contactPhone"
            autoCapitalize="none"
            error={store.getValidationError("contactPhone")}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              formData.onChange("contactPhone", e.target.value);
              store.clearValidationError("contactPhone");
            }}
            value={formData.getValue("contactPhone")}
          />
          <Input
            label="Email"
            className={styles.field}
            placeholder="yourbusiness@email.com"
            id="contactEmail"
            autoCapitalize="none"
            error={formData.getError("contactEmail") || store.getValidationError("contactEmail")}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              formData.onChange("contactEmail", e.target.value);
              store.clearValidationError("contactEmail");
            }}
            value={formData.getValue("contactEmail")}
          />

          <Input
            label="Website"
            className={styles.field}
            placeholder="https://webpage.com"
            id="contactWebsite"
            autoCapitalize="none"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              formData.onChange("contactWebsite", e.target.value);
              store.clearValidationError("contactWebsite");
            }}
            value={formData.getValue("contactWebsite")}
          />
          <FieldError errorText={formData.getError("contactWebsite") || store.getValidationError("contactWebsite")} />
        </>
      )}
    </div>
  );

  if (store.firstUse || !isFilled) {
    return (
      <div className={classnames(styles.section, styles.withSeparator)}>
        <div>{renderForm(form)}</div>
      </div>
    );
  }

  const handleCancelEditModal = () => {
    setEditMode(false);
    modalForm.onMultipleChange([
      { field: "contactWebsite", value: form.getValue("contactWebsite") },
      { field: "contactPhone", value: form.getValue("contactPhone") },
      { field: "contactEmail", value: form.getValue("contactEmail") },
      { field: "switchedOnContactsDetails", value: form.getValue("switchedOnContactsDetails") },
    ]);
  };

  const handleSaveEditModal = () => {
    if (!modalForm.isValid()) {
      return;
    }
    setEditMode(false);
    if (
      ![
        modalForm.getValue("contactWebsite"),
        modalForm.getValue("contactPhone"),
        modalForm.getValue("contactEmail"),
      ].filter(Boolean).length
    ) {
      form.onChange("switchedOnContactsDetails", false);
      setFilled(false);
    } else {
      setFilled(true);
    }
    form.onMultipleChange([
      { field: "contactWebsite", value: modalForm.getValue("contactWebsite") },
      { field: "contactPhone", value: modalForm.getValue("contactPhone") },
      { field: "contactEmail", value: modalForm.getValue("contactEmail") },
      { field: "switchedOnContactsDetails", value: modalForm.getValue("switchedOnContactsDetails") },
    ]);
  };

  return (
    <>
      {renderViewContent()}
      <EditFormModalSection open={editMode} onCancel={handleCancelEditModal} onSave={handleSaveEditModal}>
        <div className={styles.section}>{renderForm(modalForm)}</div>
      </EditFormModalSection>
    </>
  );
});
