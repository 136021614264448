import { PaymentPage, PaymentPageForm, WeekdayWorkingHoursData } from "../../types/paymentPage";

export const formatWorkingHours = (hours: Nullable<WeekdayWorkingHoursData[]>): WeekdayWorkingHoursData[] => {
  if (!hours) {
    return null;
  }
  return hours.map((el) => ({
    ...el,
    open_time: !el.open_time ? "Closed" : el.open_time.substring(0, el.open_time.lastIndexOf(":")),
    close_time: !el.close_time ? null : el.close_time.substring(0, el.close_time.lastIndexOf(":")),
  }));
};

export const formatPaymentPageToForm = (firstUse: boolean, data?: PaymentPage): PaymentPageForm => ({
  backgroundImageFileData: data?.backgroundImage,
  backgroundImageFile: null,
  backgroundImage: data?.backgroundImage?.url,
  logoFileData: data?.logo,
  logoFile: null,
  logo: data?.logo?.url,
  amount: data?.amount?.toString() || "",
  paymentLinkDescription: data?.paymentLinkDescription,
  contactPhone: data?.contactPhone,
  contactWebsite: data?.contactWebsite,
  contactEmail: data?.contactEmail,
  instagramLink: data?.instagramLink,
  facebookLink: data?.facebookLink,
  linkedinLink: data?.linkedinLink,
  address: data?.address,
  workingHours: formatWorkingHours(data?.workingHours) || [],
  googleMapsPlaceId: data?.googleMapsPlaceId,
  currency: data?.currency || "GBP",
  paymentPageDescription: data?.paymentPageDescription,
  businessType: data?.businessType,
  allowCardPayments: data?.allowCardPayments,
  publicId: data?.publicId,
  maxAmount: data?.maxAmount?.toString() || "",
  paymentTemplatePublicId: data?.paymentTemplatePublicId,
  thankYouMessage: data?.thankYouMessage,
  beneficiarySortCode: data?.beneficiarySortCode,
  beneficiaryName: data?.beneficiaryName,
  tradingName: data?.tradingName,
  allowBankTransfers: data?.allowBankTransfers,
  showPayByCard: data?.showPayByCard,
  beneficiaryAccountNumber: data?.beneficiaryAccountNumber,
  switchedOnBusinessLocation: firstUse || !!data?.address,
  switchedOnAbout: firstUse || !!data?.paymentPageDescription,
  switchedOnBusinessType: firstUse || !!data?.businessType,
  switchedOnOpeningHours: firstUse || !!data?.workingHours,
  switchedOnContactsDetails: firstUse || !!data?.contactPhone || !!data?.contactEmail || !!data?.contactWebsite,
  switchedOnSocialMedia: firstUse || !!data?.instagramLink || !!data?.linkedinLink || !!data?.facebookLink,
  switchedOnLogo: firstUse || !!data?.logo?.url,
  switchedOAmount: true,
});

export const convertWorkingHoursToSubmit = (workingHours: WeekdayWorkingHoursData[]): WeekdayWorkingHoursData[] =>
  workingHours.map((el) => {
    const isClosed = !el.open_time || !el.close_time || el.open_time === "Closed" || el.close_time === "Closed";
    return {
      ...el,
      open_time: isClosed ? null : `${el.open_time}:00`,
      close_time: isClosed ? null : `${el.close_time}:00`,
    };
  });
