import React, { VFC } from "react";
import { observer } from "mobx-react-lite";
import styles from "./businessDetailsSection.module.scss";
import { Form } from "../../../../components/form/form";
import { PaymentPageForm } from "../../../../types/paymentPage";

type BusinessDetailsSectionType = {
  form: Form<PaymentPageForm>;
};

export const BusinessDetailsSection: VFC<BusinessDetailsSectionType> = observer(({ form }) => {
  const hasFilledBusinessDetails = [form].filter(Boolean).length > 0;
  return (
    <>
      {hasFilledBusinessDetails ? (
        <div className={styles.businessDetailsSection}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionTitle}>
              <span className={styles.sectionSubtitle}>Business details</span>
              <span className={styles.sectionDescription}>These are additional details included on your page</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});
