import React, { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styles from "./Payment.module.scss";
import imageCat from "../../resources/luckyCat.svg";
import imageAnna from "../../resources/annaLogo.svg";
import { formatMoney } from "../../helpers/formatMoney";
import { Policy } from "../../components/policy/policy";
import { BanksPage } from "./banks/banksPage";
import { CardPage } from "./card/cardPage";
import { useServiceFactory } from "../../app/appServices";
import { useMount } from "../../helpers/reactHooks";
import { Status } from "../../components/status/status";
import { ErrorState } from "../../helpers/error";
import { PaymentInfo } from "../../types/payment";

type PaymentSectionProps = {
  paymentType: "banks" | "card";
};

export const Payment: FC<PaymentSectionProps> = observer(({ paymentType }) => {
  const store = useServiceFactory((x) => x.createPaymentStore());
  const [searchParams] = useSearchParams();

  useMount(() => store.load(searchParams.get("payment_request_id")));

  const renderPaymentForm = (details: PaymentInfo): ReactNode => {
    if (paymentType === "banks") {
      return <BanksPage details={details} />;
    }

    return <CardPage details={details} />;
  };

  const renderContent = (): ReactNode => {
    if (store.state === "loading") {
      return <Status type="progress" />;
    }
    if (store.state instanceof ErrorState) {
      return <Status type="error" title="Something goes wrong..." text={store.state.message} canReload />;
    }

    const logo = store.state.logoUrl || imageCat;

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {store.state.backUrl && (
            <div className={styles.backLink}>
              <a href={store.state.backUrl}>Back</a>
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.cat}>
              <img src={logo} alt="customer logo" />
            </div>
            <div className={styles.detailsPaymentTo}>Payment to:</div>
            <div className={styles.detailsPaymentReceiver}>{store.state.toAccountName}</div>
            <div className={styles.detailsAmount}>{formatMoney(Number(store.state.amount), store.state.currency)}</div>
            {store.state.reference && (
              <div className={styles.detailsReference}>{`Reference: ${store.state.reference}`}</div>
            )}
            <div className={styles.detailsDescription}>{store.state.description}</div>
            <div className={styles.detailsAnnaLogo}>
              <img src={imageAnna} alt="ANNA logo" />
              {store.state.backUrl && (
                <div className={styles.backLink}>
                  <a href={store.state.backUrl}>Back</a>
                </div>
              )}
            </div>
          </div>
          {renderPaymentForm(store.state)}
        </div>
        <Policy paymentType={paymentType} withLogo />
      </div>
    );
  };

  return <>{renderContent()}</>;
});
