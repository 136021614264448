import { AnnaResponseRaw, Api } from "./api";

export function gaSendUserEvent(eventName: string, eventValue: Nullable<string> = null): void {
  if (window.ga) {
    window.ga("send", "event", "user", eventName, eventValue);
  }
}

export const invoiceLinkAnalyticsEvents = {
  screen: "invoice-link-screen",
  pay: "invoice-link-click-pay",
  downloadInvoicePdf: "invoice-link-click-download-invoice-pdf",
  downloadReceiptPdf: "invoice-link-click-download-receipt-pdf",
  refreshPage: "invoice-link-click-refresh-page",
  documentStorage: "invoice-link-click-document-storage",
  footerVariation: "invoice-link-footer-variation",
  footerVariationActivation: "invoice-link-footer-variation-activated",
};

export const gaEventName = {
  clickConnectByTrueLayer: "click pay by banks",
  clickConnectByStripe: "click pay by card",
  clickFaq: "click faq",
  clickStartAcceptPayments: "click start accepting payments",
};

const ANNA_ANALYTICS_PREFIX = "payme-";
const ANNA_ANALYTICS_SESSION_ID = "anna_analytics_session_id";

type AnalyticsToken = {
  session_id: string;
};

export class AnalyticsApiClient {
  private _api: Api;

  constructor(api: Api) {
    this._api = api;
  }

  async postAnnaAnalyticsEvent(eventType: string, payload = {}): Promise<void> {
    let sessionId = localStorage.getItem(ANNA_ANALYTICS_SESSION_ID);
    try {
      if (!sessionId) {
        const { session_id: sessionIdFromServer } = await this.apiGetAnalyticsToken();
        localStorage.setItem(ANNA_ANALYTICS_SESSION_ID, sessionIdFromServer);
        sessionId = sessionIdFromServer;
      }
      const eventData = {
        event_type: `${ANNA_ANALYTICS_PREFIX}${eventType}`,
        session_id: sessionId,
        payload,
      };
      await this.apiLogAnalyticsEvent(eventData);
    } catch (e) {
      console.warn(e);
    }
  }

  async apiGetAnalyticsToken(): Promise<{ session_id: string }> {
    const response = await this._api.get<AnnaResponseRaw<AnalyticsToken>>("/api/webtics/i/session");

    return response.data.data;
  }

  apiLogAnalyticsEvent(data: Record<string, unknown>): Promise<unknown> {
    return this._api.post("/api/webtics/i/event", data);
  }
}
