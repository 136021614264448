import React, { ChangeEvent, useState, VFC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, TextArea } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { FormSectionType } from "../../obPaymentPageForm";
import styles from "./aboutSection.module.scss";
import { Toggle } from "../../../../components/toggle/toggle";
import { useFactory } from "../../../../helpers/reactHooks";
import { Form } from "../../../../components/form/form";
import { EditFormModalSection } from "../editFormSection/editFormModalSection";
import { Input } from "../../../../components/input/input";

export const AboutSection: VFC<FormSectionType> = observer(({ form, store }) => {
  const { paymentPageDescription, businessType, switchedOnAbout, switchedOnBusinessType } = form.values;
  const modalForm = useFactory(
    () => new Form({ paymentPageDescription, businessType, switchedOnAbout, switchedOnBusinessType })
  );
  const [isFilled, setFilled] = useState(!!form.getValue("paymentPageDescription"));

  const [editMode, setEditMode] = useState(false);

  const handleChangeToggle = (value, formData) => {
    formData.onChange("switchedOnAbout", value);
    if (!value) {
      formData.onChange("paymentPageDescription", "");
      formData.onChange("businessType", "");
    }
  };

  const handleChangeToggleBusinessType = (value) => {
    modalForm.onChange("switchedOnBusinessType", value);
    if (!value) {
      modalForm.onChange("businessType", "");
    }
  };

  const renderForm = (formData) => (
    <div className={classNames(styles.section, styles.withSeparator)}>
      <div className={styles.aboutSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            <span className={styles.sectionSubtitle}>About us</span>
            <span className={styles.sectionDescription}>
              Include a short description about your business or services
            </span>
          </div>
          <Toggle
            value={formData.getValue("switchedOnAbout")}
            onChange={(value) => handleChangeToggle(value, formData)}
          />
        </div>

        {formData.getValue("switchedOnAbout") && (
          <TextArea
            className={classNames(styles.fieldContainer, styles.textareaField)}
            placeholder="e.g. The Happiness Company has been spreading smiles since 1999"
            onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
              formData.onChange("paymentPageDescription", e.target.value);
            }}
            value={formData.getValue("paymentPageDescription")}
          />
        )}
      </div>
    </div>
  );

  const renderBusinessTypeFormSection = () => (
    <div className={styles.section}>
      <div className={styles.aboutSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            <span className={styles.sectionSubtitle}>Business type</span>
            <span className={styles.sectionDescription}>
              Add the type of business do you have e.g. Gentlemen Barbers
            </span>
          </div>
          <Toggle value={modalForm.getValue("switchedOnBusinessType")} onChange={handleChangeToggleBusinessType} />
        </div>

        {modalForm.getValue("switchedOnBusinessType") && (
          <Input
            label="Business type"
            className={styles.fieldContainer}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              modalForm.onChange("businessType", e.target.value);
            }}
            value={modalForm.getValue("businessType")}
          />
        )}
      </div>
    </div>
  );

  const renderViewContent = () => (
    <div className={styles.viewSection}>
      <div className={styles.aboutSection}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionViewTitle}>
            <span className={styles.sectionSubtitle}>About</span>
          </div>
          <IconButton icon="actionEdit" iconColor="themeGreen" size="s" onClick={() => setEditMode(true)} />
        </div>

        <span className={styles.paymentPageDescription}>{paymentPageDescription}</span>
      </div>
    </div>
  );

  if (store.firstUse || !isFilled) {
    return renderForm(form);
  }

  const handleCancelEditModal = () => {
    setEditMode(false);
    modalForm.onMultipleChange([
      { field: "paymentPageDescription", value: form.getValue("paymentPageDescription") },
      { field: "businessType", value: form.getValue("businessType") },
      { field: "switchedOnAbout", value: form.getValue("switchedOnAbout") },
    ]);
  };

  const handleSaveEditModal = () => {
    setEditMode(false);
    if (!modalForm.getValue("paymentPageDescription")) {
      form.onChange("switchedOnAbout", false);
      setFilled(false);
    } else {
      setFilled(true);
    }
    form.onMultipleChange([
      { field: "paymentPageDescription", value: modalForm.getValue("paymentPageDescription") },
      { field: "businessType", value: modalForm.getValue("businessType") },
      { field: "switchedOnAbout", value: modalForm.getValue("switchedOnAbout") },
    ]);
  };

  return (
    <>
      {renderViewContent()}
      <EditFormModalSection open={editMode} onCancel={handleCancelEditModal} onSave={handleSaveEditModal}>
        <div>
          {renderForm(modalForm)}
          {renderBusinessTypeFormSection()}
        </div>
      </EditFormModalSection>
    </>
  );
});
