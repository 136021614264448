import React, { FC } from "react";
import styles from "./Policy.module.scss";
import imageTrueLayer from "../../resources/logoTruelayer.svg";
import imageStripe from "../../resources/logoStripe.svg";

type PolicyProps = {
  className?: string;
  paymentType: "banks" | "card";
  withLogo?: boolean;
  raw?: boolean;
};

export const Policy: FC<PolicyProps> = ({ raw, className, paymentType, withLogo = false }) => {
  if (paymentType === "banks") {
    return (
      <div className={className}>
        {withLogo && (
          <div className={styles.footer}>
            <div>
              <p>Secured by</p> <img src={imageTrueLayer} alt="" />
            </div>
          </div>
        )}
        <div className={raw ? undefined : styles.policy}>
          By clicking on the preferred payment method button you are permitting our partner, TrueLayer LTD, to initiate
          a payment from your account. You also agree to their{" "}
          <a href="https://truelayer.com/enduser_tos">End User Terms of use</a>
          {" and "}
          <a href="https://truelayer.com/privacy">Privacy Policy</a>
          {". "}
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      {withLogo && (
        <div className={styles.footer}>
          <div>
            <p>Secured by</p> <img src={imageStripe} alt="" />
          </div>
        </div>
      )}
      <div className={raw ? undefined : styles.policy}>
        By clicking on the preferred payment method button you are permitting our partner, Stripe, to initiate a payment
        from your account. You also agree to their{" "}
        <a href="https://stripe.com/payment-terms/legal">End User Terms of use</a>
        {" and "}
        <a href="https://stripe.com/privacy">Privacy Policy</a>
        {". "}
      </div>
    </div>
  );
};
